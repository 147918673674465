








































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  getAccounts,
  getAPIConfiguration,
  getSpotPairs,
  isUserBuyback,
  refreshOwner, setKYC1,
  TradePair,
} from '@/clients/cpinblocks'
import { env } from '@/utils'
import { Conf } from '@/models/Conf'
import AlertDialog from '@/components/AlertDialog.vue'
import ClipboardCopy from '@/components/ClipboardCopy.vue'
import TradeVolumeInfo from '@/components/TradeVolumeInfo.vue'
import { Account } from '@/models/Account'
import CountryFlag from 'vue-country-flag'
import UserWallets from '@/components/UserWallets.vue'
import User2FA from '@/components/User2FA.vue'
import { mdiTelegram } from '@mdi/js'
import { KYType } from '@/models/KYState'
import { Iban } from '@/models/User'
import AddCheckout from "@/components/AddCheckout.vue";
import AddWireTransferEUR from "@/components/AddWireTransferEUR.vue";

@Component({
  computed: {
    KYType () {
      return KYType
    },
  },
  components: {
    AddWireTransferEUR,
    AddCheckout,
    ClipboardCopy,
    AlertDialog,
    CountryFlag,
    TradeVolumeInfo,
    User2FA,
    UserWallets,
  },
  metaInfo: {
    title: env('VUE_APP_NAME'),
  },
})
export default class AppLayout extends Vue {
  private accounts: Account[] = []
  private authenticated = false
  private cleanupDate = '2024-05-15'
  private conf: Conf | null = null
  private drawer = false
  private isBuyback: boolean | undefined = false
  private loading = true
  private mdiTelegram = mdiTelegram
  private showUser2FADialog = false
  private showUserWalletsDialog = false
  private signinLoading = false
  private showCheckout = false
  private showWireTransferEUR = false
  private supportURL = 'https://support.ib.exchange'
  private spotOrders: Array<TradePair> = []
  private valueTimeLeft2FA = 0
  private valueTimeLeft2FATimer?: ReturnType<typeof setTimeout>

  private get incompleteIbanAlert (): boolean {
    const ibans: Iban[] = this.$store.getters.ibans
    return ibans.some((iban) => iban.accountHolderFullName === undefined) || false
  }

  private get app (): boolean {
    // default value is true
    let result = true
    if (this.$route.meta !== undefined && this.$route.meta.menu !== undefined) {
      result = this.$route.meta.menu
    }
    return result
  }

  private get getExplorable (): string[] {
    if (this.conf?.explorable) {
      return [...new Set(this.conf.explorable)].sort()
    }
    return []
  }

  private get accountLocked (): boolean {
    return this.$store.state.owner.locked === 'SOFT' || this.$store.state.owner.locked === 'HARD'
  }

  private get notFungibleCurrencies (): string[] {
    const result = []
    for (const acc of this.accounts) {
      if (acc.tokenType === 'NOT_FUNGIBLE' && result.indexOf(acc.currency) < 0) {
        result.push(acc.currency)
      }
    }
    return result
  }

  get createdBeforeCleanup(): boolean {
    return new Date(this.$store.state.owner.createdAt).getTime() < new Date(this.cleanupDate).getTime();
  }

  private get accountAlert (): boolean {
    return this.mfaAlert || this.incompleteIbanAlert
  }

  private get mfaAlert (): boolean {
    return !this.$store.getters.enabled2fa || this.$store.getters.locked2fa
  }

  private enabled2fa (): boolean {
    return this.$store.getters.enabled2fa
  }

  private get kycOk (): boolean {
    return this.$store.state.owner?.kyLevel > 0
  }

  private get kyc2fa (): boolean {
    return this.$store.getters.kyc2fa
  }

  private get appEnv (): string {
    return env('VUE_APP_ENV')
  }

  private get telegramURL (): string {
    if (this.appEnv !== 'prod') {
      return 'https://t.me/ibexbeta'
    }
    return 'https://t.me/inblocksexchange'
  }

  beforeDestroy () {
    this.valueTimeLeft2FATimer && clearInterval(this.valueTimeLeft2FATimer)
  }

  private async mounted (): Promise<void> {
    // FIXME: this should not be required
    if (this.$router.currentRoute.meta?.layout === 'empty') {
      return
    }
    // to deal with mouldy JWT in the $store on reload
    if (this.$store.getters.parsedJWT && this.$store.getters.parsedJWT.exp && Number.parseInt(this.$store.getters.parsedJWT.exp) < new Date().getTime() / 1000) {
      this.$store.commit('jwt', null)
      window.location.reload()
    } else {
      this.$keycloak.onReady = this.refresh
    }
    this.valueTimeLeft2FATimer = setInterval(this.refreshTimeLeft2FA, 100)
  }

  private goToHomepage (): void {
    window.location.href = window.location.origin
  }

  private goToGoatLevel (): void {
    const path = '/goat'
    if (this.$route.path !== path) {
      this.$router.push(path)
    }
  }

  @Watch('$store.state.jwt')
  private async watchJWT (value: string | null, oldValue: string | null): Promise<void> {
    if (!oldValue && value) {
      await refreshOwner(this.$store.state.jwt, this.$store)
      this.accounts = await getAccounts(this.$store.state.jwt, false)
    }
  }

  private async refreshTimeLeft2FA (): Promise<void> {
    const weak2FADuration = this.conf?.weak2FADuration
    const code2FATimestamp = this.$store.state.code2FATimestamp
    const now = new Date().getTime() / 1000
    const validityEndTime = code2FATimestamp + weak2FADuration
    if (now && code2FATimestamp && weak2FADuration && now < validityEndTime) {
      this.valueTimeLeft2FA = 100 - (100 * ((validityEndTime - now) / weak2FADuration))
    } else {
      this.valueTimeLeft2FA = 0
    }
  }

  private async refresh (authenticated: boolean): Promise<void> {
    // FIXME: this should not be required
    this.$store.commit('owner', this.$store.state.owner)
    if (this.$router.currentRoute.meta?.layout === 'empty') {
      return
    }
    await this.$plausible.enableAutoPageviews()
    if (authenticated) {
      this.authenticated = true
      this.loading = true
      if (this.$store.state.locale) {
        this.$i18n.locale = 'en-GB' // this.$store.state.locale
      }
      if (this.$store.state.darkMode) {
        this.$vuetify.theme.dark = this.$store.state.darkMode
      }
      this.conf = await getAPIConfiguration()
      if (this.$store.state.jwt && this.$store.getters.parsedJWT.exp * 1000 >= new Date().getTime()) {
        await refreshOwner(this.$store.state.jwt, this.$store)
        this.accounts = await getAccounts(this.$store.state.jwt, false)
        this.isBuyback = await isUserBuyback(this.$store.state.jwt)
        this.spotOrders = await getSpotPairs()
      }
      this.loading = false
    } else {
      this.authenticated = false
    }
  }

  private async signout (): Promise<void> {
    await this.$store.commit('reset')
    await this.$keycloak.clearToken()
    await this.$keycloak.logout({ redirectUri: window.location.origin })
    this.drawer = false
  }

  private openInNewTab (url: string): void {
    window.open(url, '_blank')
  }

  private switchDarkMode (): void {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    this.$store.commit('darkMode', this.$vuetify.theme.dark)
  }

  private getImage(levelId: any): string {
    if (this.$vuetify.theme.dark) {
      return `/goat/${levelId.toLowerCase()}_d.svg`
    } else {
      return `/goat/${levelId.toLowerCase()}_l.svg`
    }
  }

  async autoKYC (): Promise<void> {
    await setKYC1(this.$store.state.jwt)
    await refreshOwner(this.$store.state.jwt, this.$store)
    await this.refresh(true)
  }

  private onClickSpot (): void {
    if (!this.kycOk) {
      this.$router.push('/ky')
    } else if (!this.enabled2fa()) {
      this.showUser2FADialog = true
    } else {
      this.$router.push('/spot')
    }
  }


  private onClickWithdraw (): void {
    if (!this.kycOk) {
      this.$router.push('/ky')
    } else if (!this.enabled2fa()) {
      this.showUser2FADialog = true
    } else {
      this.showWireTransferEUR = true
    }
  }

  private onClickOTC (): void {
    if (!this.kycOk) {
      this.$router.push('/ky')
    } else if (!this.enabled2fa()) {
      this.showUser2FADialog = true
    } else {
      this.$router.push('/otc')
    }
  }


}
