








import { Component, Vue } from 'vue-property-decorator'
import { env } from '@/utils'

@Component({
	components: {
	},
	metaInfo: {
		title: env('VUE_APP_NAME'),
	},
})
export default class EmptyLayout extends Vue {
	private loading = true

	private goToDashboard (): void {
		window.location.href = window.location.origin + '/dashboard'
	}

	private async mounted (): Promise<void> {
		// we make sure the theme used is not the dark one on public pages
	  this.$vuetify.theme.dark = false
		this.loading = false
	}
}
