































































































































import { Component, Vue } from 'vue-property-decorator'
import { env } from '@/utils'
import { KeycloakOnLoad } from 'keycloak-js'

@Component({
	components: {},
	metaInfo: {
		title: env('VUE_APP_NAME'),
	},
})
export default class PublicLayout extends Vue {
	private footersCategories = [
		{
			name: 'ABOUT US',
			items: [{
				name: 'Terms & Conditions',
				link: 'https://tc.ib.exchange',

			}, {
				name: 'Announcements',
				link: 'https://blog.ib.exchange/en-news/',
			}],
		},
		{
			name: 'SERVICES',
			items: [{
				name: 'Listing application',
				link: 'https://ibexchange.typeform.com/companyentry',

			}],
		},
		{
			name: 'SUPPORT',
			items: [{
				name: 'Help Center',
				link: 'https://support.ib.exchange',

			}, {
				name: 'WikIBEx Center',
				link: 'https://wiki.ib.exchange',

			}],
		},
		{
			name: 'COMMUNITY',
			items: [{
				name: 'Official Twitter feed',
				link: 'https://twitter.com/ibcexchange',

			}, {
				name: 'Official Telegram group',
				link: 'https://t.me/inblocksexchange',

			}, {
				name: 'IBEx Hotnews',
				link: 'https://t.me/ibexhot',

			}, {
				name: 'AMA on Youtube',
				link: 'https://www.youtube.com/@ibexchange',
			}, {
				name: '#Leaks Twitter feed',
				link: 'https://twitter.com/inblocksleaks',
			}],
		}]

	private authenticated = false
	private loading = true
	private drawer = false

	private redirectToDashboard (): void {
		window.location.href = window.location.origin + '/dashboard'
	}

	private goToHomepage (): void {
		window.location.href = window.location.origin
	}

	private redirectToRegister (): void {
		this.$keycloak.init({ onLoad: 'check-sso' })
		this.$keycloak.register({redirectUri: window.location.origin + '/dashboard' })
	}

  private openICO (): void {
    if (this.$keycloak.authenticated) {
      window.location.href = window.location.origin + '/ico'
    } else {
      window.location.href = window.location.origin + '/public/ico'
    }
  }

	private openExplorer (): void {
		window.location.href = window.location.origin + '/public/explorer'
	}

	private openSpot (): void {
		if (this.$keycloak.authenticated) {
			window.location.href = window.location.origin + '/spot'
		} else {
			window.location.href = window.location.origin + '/public/spot'
		}
	}

	private async mounted (): Promise<void> {
		// we make sure the theme used is not the dark one on public pages
		this.$vuetify.theme.dark = false
		const initOptions = {
			url: env('VUE_APP_IBEX_AUTH_ENDPOINT'),
			realm: 'inblocks-ibex',
			clientId: 'ibex-ui',
			onLoad: 'check-sso' as KeycloakOnLoad,
		}
		await this.$keycloak.init(initOptions)
		this.$keycloak.onReady = this.refresh
		this.loading = false
	}

	private signIn (): void {
		this.$store.commit('reset')
		this.redirectToDashboard()
	}

	private async signout (): Promise<void> {
		await this.$store.commit('logout')
		await this.$keycloak.clearToken()
		try {
			await this.$keycloak.logout({ redirectUri: window.location.origin })
		} catch (error) {
			// do nothing
			// TODO Check that KC is really clean now
		}
	}

	private async refresh (authenticated: boolean): Promise<void> {
		await this.$plausible.enableAutoPageviews()
		if (authenticated) {
			this.authenticated = true
		} else {
			this.authenticated = false
		}
		this.loading = false
	}
}
