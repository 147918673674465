import { jsonArrayMember, jsonMember, jsonObject } from '@/typedjson'

@jsonObject
export class NFT {
	@jsonMember tokenId!: string
	@jsonMember collection!: string
	@jsonMember amount?: number
	@jsonMember name?: string
	@jsonMember image?: string
	@jsonMember description?: string
	@jsonMember version?: number
}

@jsonObject
export class NFTResponse {
	@jsonMember next?: {
		href: string
		rel: string
	}

	@jsonArrayMember(NFT) response!: NFT[]
}
