import { jsonArrayMember, jsonMember, jsonObject } from '@/typedjson'
import { v4 as uuid } from 'uuid'
import { ResidenceAddress } from '@/models/ResidenceAddress'

@jsonObject
export class TaxInformation {
	constructor (
		public taxPayerId: string,
		public country: string
	) {
	}
}

@jsonObject
export class AddressId {
	constructor (
		public provider: string,
		public id: string
	) {
	}
}

export enum FamilySituation {
	SINGLE = 'SINGLE',
	MARRIED = 'MARRIED',
	LIVE_IN_PARTNERSHIP = 'LIVE_IN_PARTNERSHIP',
	WIDOW = 'WIDOW'
}

@jsonObject
export class Identity {
	@jsonMember gender = ''
	@jsonMember fullFirstName = ''
	@jsonMember lastName = ''
	@jsonMember nationality = ''
	@jsonMember countryOfBirth = ''
	@jsonMember cityOfBirth = ''
	@jsonMember birthDate = ''
	@jsonMember phoneNumber = ''
	@jsonMember pep: boolean | undefined
	@jsonMember familySituation: FamilySituation | undefined
	@jsonMember(ResidenceAddress) residenceAddress: ResidenceAddress = new ResidenceAddress()
	@jsonMember(AddressId) addressId?: AddressId
	@jsonArrayMember(TaxInformation) taxInformation?: TaxInformation[]

	get isComplete (): boolean {
		return !!this.gender && !!this.fullFirstName && !!this.lastName && !!this.nationality &&
			!!this.countryOfBirth && !!this.cityOfBirth && !!this.birthDate && !!this.phoneNumber &&
			this.pep !== undefined && !!this.familySituation && this.residenceAddress.isComplete
	}
}

export enum FundsOrigin {
	WAGES_SALARIES_PENSIONS_ALLOWANCES = 'WAGES_SALARIES_PENSIONS_ALLOWANCES',
	BANK_SAVINGS_BANK_SAVINGS_INCOME = 'BANK_SAVINGS_BANK_SAVINGS_INCOME',
	DISPOSAL_OF_FINANCIAL_ASSETS = 'DISPOSAL_OF_FINANCIAL_ASSETS',
	CRYPTO_ASSETS_PORTFOLIO = 'CRYPTO_ASSETS_PORTFOLIO',
	SALE_OF_MOVABLE_OR_IMMOVABLE_PROPERTY = 'SALE_OF_MOVABLE_OR_IMMOVABLE_PROPERTY'
}

export const PlannedInvestShares = [10, 20, 30, 40, 50]

export enum RiskTolerance {
	LOW = 'LOW',
	MODERATE = 'MODERATE',
	EXPERT = 'EXPERT',
}

export enum Knowledge {
	BEGINNER = 'BEGINNER',
	INTERMEDIATE = 'INTERMEDIATE',
	EXPERT = 'EXPERT',
}

@jsonObject
export class InvestorProfile {
	@jsonArrayMember(String) fundsOrigin: FundsOrigin[] = []
	@jsonMember maxInvestmentPercentage!: number
	@jsonMember riskTolerance!: RiskTolerance
	@jsonMember experience = ''
	@jsonMember certified = false

	get isComplete (): boolean {
		return this.fundsOrigin.length > 0 && !!this.maxInvestmentPercentage && !!this.riskTolerance && !!this.experience && this.certified
	}
}

@jsonObject
export class KYCInformation {
	@jsonMember(Identity) identity?: Identity
	@jsonMember(InvestorProfile) investorProfile: InvestorProfile = new InvestorProfile()
}

export enum Raise {
	UNKNOWN = 'UNKNOWN',
	RAISE_ICO = 'RAISE_ICO',
	RAISE_STO_SPV = 'RAISE_STO_SPV',
}

export class InvestorProfessionalProfile {
	@jsonMember invest = false
	@jsonMember raise: Raise | null = null

	get isComplete (): boolean {
		return this.invest !== undefined && this.raise !== undefined
	}
}

export enum LegalStatus {
	SAS = 'SAS',
	SASU = 'SASU',
	SARL = 'SARL',
	SC = 'SC',
	SASC = 'SASC',
	SASNC = 'SASNC',
	OTHER = 'OTHER',
}

export class CompanyIdentity {
	@jsonMember french = true
	@jsonMember authority = false
	@jsonMember siret = ''
	@jsonMember status!: LegalStatus
	@jsonMember creationDate = ''
	@jsonMember name = ''
	@jsonMember(ResidenceAddress) residenceAddress: ResidenceAddress = new ResidenceAddress()

	get isComplete (): boolean {
		return this.french !== undefined && this.authority !== undefined && !!this.siret && !!this.status && !!this.creationDate && !!this.name && this.residenceAddress.isComplete
	}
}

export enum BusinessLine {
	AUDIOVISUAL = 'AUDIOVISUAL',
	AGRICULTURE = 'AGRICULTURE',
	AERONAUTICS = 'AERONAUTICS',
	MILITARY = 'MILITARY',
	ARTISAN = 'ARTISAN',
	AUDIT_ACCOUNTING = 'AUDIT_ACCOUNTING',
	BANKING_INSURANCE_FINANCE = 'BANKING_INSURANCE_FINANCE',
	CONSTRUCTION = 'CONSTRUCTION',
	TRADE = 'TRADE',
	COMMUNICATION = 'COMMUNICATION',
	LAW = 'LAW',
	ENERGY = 'ENERGY',
	PUBLIC_ADMIN_EDUCATION = 'PUBLIC_ADMIN_EDUCATION',
	HOTEL_INDUSTRY = 'HOTEL_INDUSTRY',
	REAL_ESTATE = 'REAL_ESTATE',
	IT = 'IT',
	MINING = 'MINING',
	MARKETING_AND_CORPORATE_SERVICE = 'MARKETING_AND_CORPORATE_SERVICE',
	MEDICAL = 'MEDICAL',
	TEXTILE_FASHION = 'TEXTILE_FASHION',
	POLITICS = 'POLITICS',
	HR = 'HR',
	SOCIAL = 'SOCIAL',
	VOLUNTARY = 'VOLUNTARY',
	ENTERTAINMENT = 'ENTERTAINMENT',
	SPORTS = 'SPORTS',
	TOURISM = 'TOURISM',
	GAMBLING_AND_CASINO = 'GAMBLING_AND_CASINO',
	OTHER = 'OTHER'
}

export enum ShareCapital {
	LESS_THAN_10K = 'LESS_THAN_10K',
	FROM_10_TO_100K = 'FROM_10_TO_100K',
	MORE_THAN_100K = 'MORE_THAN_100K'
}

export class CompanyInformation {
	@jsonMember lineOfBusiness!: BusinessLine
	@jsonMember countryOfRegistration = ''
	@jsonMember shareCapital!: ShareCapital

	get isComplete (): boolean {
		return !!this.lineOfBusiness && !!this.countryOfRegistration && !!this.shareCapital
	}
}

export class Beneficial {
	constructor (
		public index: number,
		public uniqueId: string = uuid(),
		public firstName = '',
		public lastName = '',
		public dateOfBirth = '',
		public nationality = '',
		public usCitizen: boolean | null = null) {
	}

	get isComplete (): boolean {
		return !!this.index && !!this.uniqueId && !!this.firstName && !!this.lastName && !!this.dateOfBirth && !!this.nationality && this.usCitizen !== null
	}
}

export class FormUtilities {
	@jsonMember corporateAccount: boolean | null = null
	@jsonMember legalEntity: boolean | null = null
	@jsonMember certify = false
}

export class KYBInformation {
	@jsonMember(Identity) legalRepresentative?: Identity
	@jsonMember(Beneficial) beneficials: Beneficial[] = [new Beneficial(0)]
	@jsonMember(CompanyInformation) companyInfo: CompanyInformation = new CompanyInformation()
	@jsonMember(CompanyIdentity) companyIdentity: CompanyIdentity = new CompanyIdentity()
	@jsonMember(InvestorProfessionalProfile) investorProfessionalProfile: InvestorProfessionalProfile = new InvestorProfessionalProfile()
}

export class KYInformation {
	@jsonMember(KYBInformation) kybInfo: KYBInformation = new KYBInformation()
	@jsonMember(KYCInformation) kycInfo: KYCInformation = new KYCInformation()
	@jsonMember(Identity) identity: Identity = new Identity()
	@jsonMember(FormUtilities) formUtilities: FormUtilities = new FormUtilities()
}
