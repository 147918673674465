import { jsonObject } from '@/typedjson'

@jsonObject
export class ResidenceAddress {
	constructor (
		public country: string = '',
		public city: string = '',
		public postCode: string = '',
		public street: string = ''
	) {
	}

	get isComplete (): boolean {
		return !!this.city && !!this.country && !!this.postCode && !!this.street
	}
}
