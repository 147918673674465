






































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
	changeEmailSharingConsent,
	getAccounts,
	getAPIConfiguration,
	getCheckoutSessionId,
	getEmailSharingConsent,
	getPaymentIntent,
	getPaymentLink,
	getProjectLaunchpadConf,
	getSpotPairs,
	OnboardingPaymentIntent,
	PaymentSession,
	payUsingCrypto,
	submitCheckout,
	TradePair,
} from '@/clients/cpinblocks'
import { BigNumber } from 'bignumber.js'
import type { Currency, TokenType, ValidationRules } from '@/types'
import { alertRawError, convertValueToBigNumber } from '@/utils'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import User2FA from '@/components/User2FA.vue'
import { Conf } from '@/models/Conf'
import { Account } from '@/models/Account'

@Component({
	components: {
		Code2FADialog,
		CurrencyInput,
		User2FA,
	},
})
export default class Checkout extends Vue {
	@Prop() intentId?: string
	@Prop() amount?: BigNumber
	@Prop({ default: false }) message!: boolean
	@Prop() messageToken?: string // only used if message is true
	@Prop() token?: Currency
	@Prop() tokenType?: TokenType
	@Prop({ default: false }) widget?: boolean

	private accounts: Account[] = []
	private buyingWithCrypto = false
	private cardProvider = 'easytransac'
	private conf: Conf | null = null
	private creating = false
	private firstname = ''
	private isValid = false
	private lastname = ''
	private loading = true
	private paymentIntent: OnboardingPaymentIntent | null = null
	private paymentMethod = this.paymentMethods[0].value
	private paymentMethodIndex = 0
	private paymentSession: PaymentSession | null = null
	private paymentUrl: string | null = null
	private precisionAmount = 0
	private precisionUnitPrice = 4
	private realQuantity = new BigNumber(0)
	private currentAmount: BigNumber = new BigNumber(0)
	private currentToken: Currency | null = null
	private currentTokenType: TokenType | null = null
	private currentUnitPrice: BigNumber | null = new BigNumber(0)
	private interval: ReturnType<typeof setTimeout> | null = null
	private show2FADialog = false
	private totalAmount: string | null = null
	private paymentCurrency = 'EUR'
	private showBuyConfirmation = false
	private showUser2FSetupADialog = false
	private spotPairs: TradePair[] = []
	private updating = false
	private emailSharing = false
	private privacyPolicyUrl: string | null = null
	// FIXME: use an API call to get this kind of info (or the IBAN)
	private stoCurrencies: Currency[] = ['PAYFIT']

	get lang (): string {
		return this.paymentIntent?.lang ?? 'en'
	}

	private get maxPaidAmount (): BigNumber {
		let result = new BigNumber(0)
		switch (this.paymentMethod) {
		case 'bank':
			result = new BigNumber(100000)
			break
		case 'card':
			let maxAmountCard = new BigNumber('0')
			if (this.paymentMethod === 'card' && this.paymentSession) {
				maxAmountCard = new BigNumber(
					new BigNumber(20000)
						.dividedBy(new BigNumber(this.paymentSession.feeVariablePercentage).dividedBy(100).plus(1))
						.minus(new BigNumber(this.paymentSession.feeFixed))
						.toFixed(0, BigNumber.ROUND_DOWN))
			}
			result = new BigNumber(maxAmountCard)
			break
		case 'crypto':
			result = this.balance(this.paymentCurrency as Currency, 'MAIN')
			break
		}
		return result
	}

	private get min (): BigNumber {
		return this.currentToken === 'IBXE' ? new BigNumber(1) : new BigNumber(10)
	}

	private get paymentCurrencies (): any[] {
		let result: any[] = []
		if (this.paymentMethod === 'card' || this.paymentMethod === 'bank') {
			result = [{
				text: 'EUR',
				value: 'EUR',
				disabled: false,
			}]
		} else {
			for (const sp of this.spotPairs) {
				if (sp.productCurrency === this.currentToken && sp.productType === this.currentTokenType && sp.checkout === 'ENABLED') {
					result.push({
						text: sp.unitPriceCurrency,
						value: sp.unitPriceCurrency,
						disabled: this.balance(sp.unitPriceCurrency, 'MAIN')?.eq(0),
					})
				}
			}
		}
		return result
	}

	private get paymentMethods (): any[] {
		if (this.widget) {
			return [
				{
					icon: 'mdi-bank-transfer',
					text: this.tr('checkout.paymentMethod.bank'),
					value: 'bank',
				},
			]
		} else if (this.currentToken !== 'IBXE') {
			return [
				{
					icon: 'mdi-credit-card-outline',
					text: this.tr('checkout.paymentMethod.card'),
					value: 'card',
				},
				{
					icon: 'mdi-bank-transfer',
					text: this.tr('checkout.paymentMethod.bank'),
					value: 'bank',
				},
				{
					icon: 'mdi-wallet-outline',
					text: this.tr('checkout.paymentMethod.crypto'),
					value: 'crypto',
				},
			]
		} else {
			return [
				{
					icon: 'mdi-credit-card-outline',
					text: this.tr('checkout.paymentMethod.card'),
					value: 'card',
				},
				{
					icon: 'mdi-bank-transfer',
					text: this.tr('checkout.paymentMethod.bank'),
					value: 'bank',
				},
			]
		}
	}

	private get rules (): ValidationRules {
		const amountBank = [
			(v?: string) => !!v || this.tr('rule.requiredField'),
			(v: string) => !convertValueToBigNumber(v).isNaN() || this.tr('rule.requiredField'),
			// (v: string) => convertValueToBigNumber(v).isLessThanOrEqualTo(this.maxPaidAmount) || this.tr('checkout.rule.lessThan100k'),
		]
		const amountCard = [
			(v?: string) => !!v || this.tr('rule.requiredField'),
			(v: string) => !convertValueToBigNumber(v).isNaN() || this.tr('rule.requiredField'),
			// (v: string) => convertValueToBigNumber(v).isLessThanOrEqualTo(this.maxPaidAmount) || this.tr('checkout.rule.lessThanOrEquals', { amount : this.maxPaidAmount })
		]
		const amountCrypto = [
			(v?: string) => !!v || this.tr('rule.requiredField'),
			(v: string) => !convertValueToBigNumber(v).isNaN() || this.tr('rule.requiredField'),
			// (v: string) => convertValueToBigNumber(v).isLessThanOrEqualTo(this.maxPaidAmount) || this.tr('checkout.rule.insufficientFundsCrypto', { max : this.maxPaidAmount })
		]
		/* if (this.currentToken === 'IBXE') {
			amountBank.push(((v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo('1') || this.tr('checkout.rule.moreThanOrEqualsToOne')))
			amountCard.push(((v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo('1') || this.tr('checkout.rule.moreThanOrEqualsToOne')))
			amountCrypto.push(((v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo('1') || this.tr('checkout.rule.moreThanOrEqualsToOne')))
		} else {
			amountBank.push(((v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo('10') || this.tr('checkout.rule.moreThanOrEqualsToTen')))
			amountCard.push(((v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo('10') || this.tr('checkout.rule.moreThanOrEqualsToTen')))
			amountCrypto.push(((v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo('10') || this.tr('checkout.rule.moreThanOrEqualsToTen')))
		} */
		return {
			amount: this.paymentMethod === 'bank' ? amountBank : (this.paymentMethod === 'card' ? amountCard : amountCrypto),
			firstname: [
				(v?: string) => !!v || this.tr('rule.requiredField'),
        (v?: string) => (v !== undefined && v !== this.lastname) || this.tr('rule.firstnameEqualsLastname'),
				(v?: string) => (v !== undefined && v.length < 32) || this.tr('checkout.rule.maxLength', { max: 32 }),
			],
			lastname: [
				(v?: string) => !!v || this.tr('rule.requiredField'),
        (v?: string) => (v !== undefined && v !== this.firstname) || this.tr('rule.firstnameEqualsLastname'),
        (v?: string) => (v !== undefined && v.length < 32) || this.tr('checkout.rule.maxLength', { max: 32 }),
			],
			required: [
				(v?: string) => !!v || this.tr('rule.requiredField'),
			],
			unitPrice: [
				(v?: string) => !!v || this.tr('rule.requiredField'),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.tr('rule.requiredField'),
				(v: string) => convertValueToBigNumber(v).isGreaterThan('0') || this.tr('checkout.rule.moreThanZero'),
			],
		}
	}

	getPaymentReference (): string {
		return `${this.$store.state.owner.ownerId.toUpperCase()}-${this.currentToken}`
	}

	isAcceptedLetter (field: string, e: any) {
		const char = String.fromCharCode(e.keyCode)
		if (/^[A-Za-z]+$/.test(char) || [' ', 'é', 'è', 'ç', '-', '\''].indexOf(char) >= 0) {
			if (field.indexOf('--') >= 0 ||
				field.indexOf('\'-') >= 0 ||
				field.indexOf('-\'') >= 0) {
				e.preventDefault()
			} else {
				return true
			}
		} else {
			e.preventDefault()
		}
	}

	async refresh (changed: string): Promise<void> {
		this.updating = true
		if (changed === 'paymentMethod') {
			if (this.paymentMethod === 'bank' || this.paymentMethod === 'card') {
				this.paymentCurrency = 'EUR'
			} else {
				this.paymentCurrency = 'IBXE'
				if (this.balance('IBXE', 'MAIN')?.eq(0)) {
					const currencies: any[] = this.paymentCurrencies
					for (const c of currencies) {
						if (!c.disabled) {
							this.paymentCurrency = c.value
						}
					}
				}
			}
		}
		if (this.interval) clearTimeout(this.interval)
		this.interval = setTimeout(async () => {
			if (this.currentToken === null || this.currentTokenType === null) {
				// should not happen
				return
			}
			if (this.paymentMethod === 'card' || this.paymentMethod === 'bank') {
				this.paymentSession = await getCheckoutSessionId(this.$store.state.jwt,
					this.cardProvider,
					this.intentId,
					this.currentAmount,
					undefined,
					this.currentToken,
					this.currentTokenType,
					this.lang)
			} else if (this.paymentMethod === 'crypto') {
				this.paymentSession = await getCheckoutSessionId(this.$store.state.jwt,
					'crypto',
					this.intentId,
					this.currentAmount,
					this.paymentCurrency as Currency,
					this.currentToken,
					this.currentTokenType,
					this.lang)
			}
			this.currentUnitPrice = this.paymentSession?.unitPrice.value ? new BigNumber(this.paymentSession?.unitPrice.value) : new BigNumber(0)
			this.realQuantity = this.paymentSession?.product.value ? new BigNumber(this.paymentSession?.product.value) : new BigNumber(0)
			let result = this.paymentSession?.price ? new BigNumber(this.paymentSession?.price).toFixed(2) : 'N/A'
			for (const test of this.rules.amount) {
				if (test(this.currentAmount.toString()) === true) {
					// do nothing
				} else {
					result = 'N/A'
				}
			}
			this.totalAmount = result
			await this.validateForm()
			this.updating = false
		}, 200)
	}

	validateForm (): void {
		(this.$refs.form as Vue & { validate: () => boolean }).validate()
	}

	private tr (k: string, v?: any): string {
		return this.$t(k, this.lang, v) ? this.$t(k, this.lang, v).toString() : ''
	}

	private cleanup (field: string) {
		if (field === 'firstname') {
			this.firstname = this.firstname
				.replaceAll(/[/&"§!€$¨^°|?.:=+#&;$%@"<>(),1234567890]/g, '')
				.replace('  ', '')
				.replace('--', '')
				.replace('\'\'', '')
				.replace('\'-', '')
				.replace('-\'', '')
		} else if (field === 'lastname') {
			this.lastname = this.lastname
				.replaceAll(/[/&"§!€$¨^°|?.:=+#&;$%@"<>(),1234567890]/g, '')
				.replace('  ', '')
				.replace('--', '')
				.replace('\'\'', '')
				.replace('\'-', '')
				.replace('-\'', '')
		}
	}

	private async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.spotPairs = await getSpotPairs()
		if (this.intentId) {
			this.paymentIntent = await getPaymentIntent(this.$store.state.jwt, this.intentId)
			this.currentAmount = new BigNumber(this.paymentIntent.amount)
			this.currentToken = this.paymentIntent.token
			this.currentTokenType = this.paymentIntent.tokenType
			this.emailSharing = true
			await changeEmailSharingConsent(this.$store.state.jwt, this.currentToken, this.emailSharing)
		} else if (this.amount && this.token && this.tokenType) {
			this.currentAmount = new BigNumber(this.amount)
			this.currentToken = this.token
			this.currentTokenType = this.tokenType
			this.emailSharing = await getEmailSharingConsent(this.$store.state.jwt, this.currentToken)
		} else {
			throw new Error('Configuration mismatch')
		}
		const projectConf = await getProjectLaunchpadConf(this.currentToken)
		this.privacyPolicyUrl = projectConf?.grpdUrlTemplate

		if (this.currentToken === 'IBXE' && this.currentTokenType === 'MAIN') {
			this.currentUnitPrice = new BigNumber(1)
		}
		if (this.currentAmount.gte(1000)) {
			this.paymentMethod = 'bank'
		}
		this.paymentSession = await getCheckoutSessionId(this.$store.state.jwt,
			this.cardProvider,
			this.intentId,
			this.currentAmount,
			this.paymentCurrency as Currency,
			this.currentToken,
			this.currentTokenType,
			this.lang)
		this.currentUnitPrice = this.paymentSession?.unitPrice.value ? new BigNumber(this.paymentSession?.unitPrice.value) : new BigNumber(0)
		this.realQuantity = this.paymentSession?.product.value ? new BigNumber(this.paymentSession?.product.value) : new BigNumber(0)
		let result = this.paymentSession?.price ? new BigNumber(this.paymentSession?.price).toFixed(2) : 'N/A'
		for (const test of this.rules.amount) {
			if (test(this.currentAmount.toString()) === true) {
				// do nothing
			} else {
				result = 'N/A'
			}
		}
		this.totalAmount = result
		this.loading = false
	}

	private async payByCard (): Promise<void> {
		if (this.currentToken && !this.intentId) await changeEmailSharingConsent(this.$store.state.jwt, this.currentToken, this.emailSharing)
		if (this.paymentSession?.id) {
			const paymentIntentId = this.intentId ? this.intentId : 'direct'
			this.paymentUrl = await getPaymentLink(this.$store.state.jwt, this.cardProvider, this.paymentSession.id, `${window.location.origin}/checkout/confirm/${paymentIntentId}/ico/${this.lang}`, `${window.location.origin}/checkout/cancel/${paymentIntentId}/ico/${this.lang}`)
			// this.paymentUrl = await getPaymentLink(this.$store.state.jwt, this.cardProvider, this.paymentSession.id, `${window.location.origin}/checkout/confirm/${paymentIntentId}`, `${window.location.origin}/checkout/cancel/${paymentIntentId}`)
			const newWin = await window.open(this.paymentUrl)
			if (!(!newWin || newWin.closed || typeof newWin.closed === 'undefined')) {
				await this.$emit('success')
			}
		} else {
			await this.$emit('fail')
		}
	}

	private balance (currency: Currency | string, type: TokenType): BigNumber {
		for (const a of this.accounts) {
			if (a.currency === currency && a.type === type) {
				return a.balance
			}
		}
		return new BigNumber(0)
	}

	private async buyUsingCrypto (object: any): Promise<void> {
		if (this.currentToken && !this.intentId) await changeEmailSharingConsent(this.$store.state.jwt, this.currentToken, this.emailSharing)
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		if (this.paymentSession && this.paymentSession.id) {
			try {
				await payUsingCrypto(this.$store.state.jwt, 'crypto', this.paymentSession.id, code2FA)
				this.buyingWithCrypto = false
				this.showBuyConfirmation = true
			} catch (error: any) {
				alertRawError(error.message)
				this.creating = false
				this.$emit('fail')
				this.buyingWithCrypto = false
			}
		}
	}

  private onClickSeeMyTokens (): void {
    if (this.$router.currentRoute.path === '/dashboard') {
      this.showBuyConfirmation = false
      this.$emit('success')
    } else {
      this.$router.push('/dashboard')
    }
  }

	private async submit (): Promise<void> {
		if (this.paymentIntent) {
			await submitCheckout(
				this.$store.state.jwt,
				this.getPaymentReference(),
				this.currentAmount.toNumber(),
				this.paymentIntent.tokenEmissionEventName ?? 'NOT PROVIDED',
				this.paymentIntent.lang
			)
			if (this.paymentIntent.tokenIssuerSiteUrl) {
				window.location.href = this.paymentIntent.tokenIssuerSiteUrl
			}
		}
	}

	private async closeBankTransfer (): Promise<void> {
		if (this.currentToken && !this.intentId) await changeEmailSharingConsent(this.$store.state.jwt, this.currentToken, this.emailSharing)
		this.$emit('success')
	}
}
