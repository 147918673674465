import { jsonArrayMember, jsonMember, jsonObject, stringMapper } from '@/typedjson'
import type { Currency, TokenCategory, TokenType } from '@/types'
import { BigNumber } from 'bignumber.js'
import { NFT } from '@/models/NFT'
import { Operation } from '@/models/Operation'

@jsonObject
export class Account {
	@jsonMember id!: string
	@jsonMember name!: string | null
	@jsonMember(stringMapper) type!: TokenType | string
	@jsonMember(stringMapper) currency!: Currency | string
	@jsonMember balance!: BigNumber
	@jsonArrayMember(NFT) inventory!: NFT[]
	@jsonArrayMember(Operation) operations!: Operation[]
	@jsonMember(stringMapper) tokenType!: TokenCategory
}
