















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Address, addWallet, editName, refreshOwner, toggleAddress } from '@/clients/cpinblocks'
import { alertError, alertRawError } from '@/utils'
import { connect, signMessage } from '@/clients/metamask'

@Component({
	components: {},
})

export default class UserWallets extends Vue {
	@Prop() id!: string

	edit = false
	allAddresses: Address[] = []
	addressName = ''
	nameValid = true
	enabling: string | null = null
	headers = [{
		text: this.$t('userWallets.dataTableTitle.address'),
		value: 'value',
	},
	{
		text: this.$t('userWallets.dataTableTitle.name'),
		value: 'name',
	},
	{
		text: this.$t('userWallets.dataTableTitle.enabled'),
		value: 'enabled',
	}]

	mounted (): void {
		this.fetchInfos()
	}

	async connexionMetamask (): Promise<void> {
		try {
			const formatedDate = new Date().toISOString()
			const messageFragment = this.$t('userWallets.message')
			const message = `[${formatedDate}] ${messageFragment}`
			try {
				const address = await connect()
				if (address !== null) {
					try {
						const signature = await signMessage(message)
						if (signature !== null) {
							try {
								await addWallet(this.$store.state.jwt, message, address, signature)
								await this.fetchInfos()
							} catch (error) {
								alertRawError(this.$t('userWallets.error.adding').toString())
							}
						}
					} catch (error) {
						alertRawError(this.$t('userWallets.error.signature').toString())
					}
				}
			} catch (error) {
				alertRawError(this.$t('userWallets.error.connexion').toString())
			}
		} catch (error) {
			alertError(error)
		}
	}

	async fetchInfos (): Promise<void> {
		try {
			await refreshOwner(this.$store.state.jwt, this.$store)
			this.allAddresses = this.$store.state.owner?.ethereum?.addresses ?? []
		} catch (error) {
			alertError(error)
		}
	}

	async toggleAddress (address: string, state: boolean): Promise<void> {
		this.enabling = address
		try {
			await toggleAddress(this.$store.state.jwt, address, state)
			await this.fetchInfos()
		} catch (error) {
			alertError(error)
		}
		this.enabling = null
	}

	copyToClipboard (address: string): void {
		navigator.clipboard.writeText(address)
	}

	toggleEditName (): void {
		this.edit = !this.edit
		this.addressName = ''
	}

	async submitChange (address: string, state: boolean, name: string): Promise<void> {
		if (this.nameValid) {
			try {
				await editName(this.$store.state.jwt, state, address, name)
				this.toggleEditName()
				this.addressName = ''
				await this.fetchInfos()
			} catch (error) {
				alertError(error)
			}
		} else {
			await this.fetchInfos()
		}
	}

	patternAddressName (text: string | undefined): string | boolean {
		text = text === undefined ? '' : text
		this.nameValid = text.length <= 25
		return this.nameValid || '25 caractères maximum'
	}
}
