import { jsonMember, jsonObject, stringMapper } from '@/typedjson'
import type { Currency, TokenType } from '@/types'
import { BigNumber } from 'bignumber.js'

@jsonObject
export class Rate {
	@jsonMember amountFrom!: BigNumber
	@jsonMember(stringMapper) currencyFrom!: Currency
	@jsonMember(stringMapper) typeFrom?: TokenType
	@jsonMember amountTo!: BigNumber
	@jsonMember(stringMapper) currencyTo!: Currency
	@jsonMember(stringMapper) typeTo?: TokenType
}
