import { jsonMember, jsonObject, stringMapper } from '@/typedjson'
import { BigNumber } from 'bignumber.js'
import type { Network, TokenType } from '@/types'
import { Rate } from './Rate'

export type OfferStatus = 'AVAILABLE' | 'ACCEPTED' | 'COMPLETED' | 'CANCELING' | 'CANCELED' | 'EXPIRED' | 'REJECTING' | 'REJECTED'

@jsonObject
export class SignedRequest {
	@jsonMember message!: string
	@jsonMember address!: string
	@jsonMember signature!: string
}

@jsonObject
export class Offer {
	@jsonMember id!: string
	@jsonMember createdAt!: Date
	@jsonMember expiresAt!: Date
	@jsonMember(stringMapper) status!: OfferStatus
	@jsonMember sellerId!: string
	@jsonMember sellerAddress!: string
	@jsonMember buyerId!: string
	@jsonMember rate!: Rate
	@jsonMember(stringMapper) type!: TokenType
	@jsonMember fee?: BigNumber
	@jsonMember(stringMapper) network!: Network
	@jsonMember confirmationsTarget!: number
	@jsonMember confirmations!: number

	// filled if status is CANCELING | CANCELED
	@jsonMember signedRequestCancellation?: SignedRequest
	@jsonMember canceledAt?: Date // date of cancel + delay if CANCELING or date of cancel if CANCELED

	// filled if status is ACCEPTED
	@jsonMember transactionHash?: string

	// filled is status is COMPLETED
	@jsonMember boughtAt?: Date
}
