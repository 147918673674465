
































import { Component, Prop, Vue } from 'vue-property-decorator'
import CodeInput from '@/components/CodeInput.vue'
import { env } from '@/utils'

@Component({
	components: { CodeInput },
})
export default class Code2FADialog extends Vue {
	@Prop() expiresAt? : string
	@Prop() show!: boolean
	@Prop({ default: 'strong' }) type!: 'strong' | 'weak'
	// 20 minutes by default
	@Prop({default: 20 * 60}) weak2FADuration?: number

	private key = Math.random().toString()
	private timeLeftSeconds: number | null = null
	private timer?: ReturnType<typeof setTimeout>

	async created () : Promise<void> {
		if (new Date().getTime() / 1000 < this.$store.state.code2FATimestamp + this.weak2FADuration &&
				this.type === 'weak') {
			this.$nextTick(async () => { await this.$emit('validate', { code: this.$store.state.code2FA }) })
		} else {
			this.timer = setInterval(this.refreshTimeLeft, 100)
		}
	}

	get displayedTitle (): string {
		let result = '2FA code'
		if (this.timeLeftSeconds && this.timeLeftSeconds < 60) {
			result += ' (' + this.timeLeftSeconds + ' seconds left)'
		}
		return result
	}

	async refreshTimeLeft (): Promise<void> {
		if (this.expiresAt) {
			this.timeLeftSeconds = Math.round((new Date(this.expiresAt).getTime() - new Date().getTime()) / 1000)
			if (this.timeLeftSeconds > 60) {
				this.timeLeftSeconds = 60
			}
			if (this.timeLeftSeconds < 1) {
				this.timer && clearInterval(this.timer)
				this.$emit('expired')
			}
		}
	}

	beforeDestroy () {
		this.timer && clearInterval(this.timer)
	}

	async onComplete (code: string): Promise<void> {
		this.key = Math.random().toString()
		await this.$emit('validate', { code: code })
	}

	async cancel (): Promise<void> {
		this.key = Math.random().toString()
		await this.$emit('cancel')
	}
}
