export enum DocumentVerificationStatus {
	NOT_SUBMITTED = 'NOT_SUBMITTED',
	DENIED = 'DENIED',
	FRAUD = 'FRAUD',
	APPROVED = 'APPROVED',
	OTHER = 'OTHER'
}

export enum KYType {
	KYB = 'KYB',
	KYC = 'KYC'
}

export enum VerificationFactor {
	RETAIL_INFO = 'RETAIL_INFO',
	INSTITUTIONAL_INFO = 'INSTITUTIONAL_INFO',
	COMPANY_INFO = 'COMPANY_INFO',
	ID_DOCUMENT = 'ID_DOCUMENT',
	PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
	KBIS = 'KBIS',
	COMPANY_STATUS = 'COMPANY_STATUS',
	COMPANY_BANK_STATEMENT = 'COMPANY_BANK_STATEMENT',
	COMPANY_FINANCIAL_STATEMENT = 'COMPANY_FINANCIAL_STATEMENT',
	BENEF_OWNERS_REGISTRY_DECLARATION = 'BENEF_OWNERS_REGISTRY_DECLARATION',
	BENEF_OWNERS_ID_DOCUMENTS = 'BENEF_OWNERS_ID_DOCUMENTS',
	BENEF_OWNERS_INFO = 'BENEF_OWNERS_INFO'
}

export class KYState {
	kycLevel?: number
	kybLevel?: number
	rejectedValidation = false
	pendingValidation = false
	pendingValidationSince?: string
	rejectedValidationAt?: string
	verificationPath?: KYType
	maxIdVerificationAttemptsExceeded = false
	pendingVerificationFactors!: VerificationFactor[]
	acceptedVerificationFactors!: VerificationFactor[]

	constructor (kyState: KYState | null) {
		Object.assign(this, kyState)
	}

	get verificationFactors (): VerificationFactor[] {
		return [...this.pendingVerificationFactors, ...this.acceptedVerificationFactors]
	}

	get isCorporateAccount (): boolean | null {
		return (this.kybLevel !== undefined || this.verificationPath === KYType.KYB) ? true
			: (this.kycLevel !== undefined || this.verificationPath === KYType.KYC) ? false : null
	}

	get isNewUser (): boolean {
		return (this.kybLevel === undefined && this.kycLevel === undefined && this.verificationPath === undefined && !this.rejectedValidation)
	}

	get kyLevel (): number {
		return (this.kycLevel !== undefined) ? this.kycLevel : (this.kybLevel !== undefined) ? this.kybLevel : 0
	}
}
