import App from '@/App.vue'
import { i18n } from '@/i18n'
import { KeycloakPlugin } from '@/plugins/keycloak'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'
import Keycloak from 'keycloak-js'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import { VuePlausible } from 'vue-plausible'
import { mask } from '@titou10/v-mask'

Vue.use(KeycloakPlugin, store)
Vue.use(VueApexCharts)
Vue.use(VueCookies)
Vue.use(VueMeta)
Vue.use(VuePlausible, {})

Vue.directive('mask', mask);

Vue.component('apexchart', VueApexCharts)

Vue.config.devtools = true
Vue.config.productionTip = false

declare module 'vue/types/vue' {
	interface Vue {
		$keycloak: Keycloak.KeycloakInstance;
	}
}

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount('#app')
