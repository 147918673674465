






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Chart from 'chart.js/auto'
import { BigNumber } from 'bignumber.js'
import { Volume } from '@/models/Volume'
import moment from 'moment'

@Component
export default class TradeVolumeInfo extends Vue {
	@Prop() busdVolume!: Volume[]
	@Prop() usdcVolume!: Volume[]
	@Prop() usdtVolume!: Volume[]

	labels: string[] = []
	powerVolume: string[] = []
	stableVolume: string[] = []
	unitPrice: string[] = []

	cpRed = '#FF2F3E'
	cpBlueLight = '#2A71E8'

	async mounted () : Promise<void> {
		if (this.busdVolume.length < 0 && this.usdtVolume.length < 0) {
			return
		}
		const volume = [this.busdVolume, this.usdcVolume, this.usdtVolume]
		var daily = new Map<string, Map<string, BigNumber>>()
		for (const vol of volume) {
			for (const o of vol) {
				const day = o.at.toISOString().substr(0, 10)
				let map = daily.get(day)
				if (!map) {
					map = new Map<string, BigNumber>()
					map.set('amountFrom', new BigNumber('+0'))
					map.set('amountTo', new BigNumber('+0'))
				}
				// should always be true
				if (map.get('amountFrom') && map.get('amountTo')) {
					const amountFrom = o.amountFrom.plus(map.get('amountFrom') ?? new BigNumber('0'))
					const amountTo = o.amountTo.plus(map.get('amountTo') ?? new BigNumber('0'))
					map.set('amountFrom', amountFrom)
					map.set('amountTo', amountTo)
					daily.set(day, map)
				}
			}
		}
		var minDate = moment(this.firstVol(this.busdVolume, this.firstVol(this.usdcVolume, this.usdtVolume))[0].at)
		while (minDate.isBefore(moment())) {
			this.labels.push(minDate.toISOString().substr(0, 10))
			const sVol: BigNumber = daily.get(minDate.toISOString().substr(0, 10))?.get('amountFrom') ?? new BigNumber('0')
			const pVol: BigNumber = daily.get(minDate.toISOString().substr(0, 10))?.get('amountTo') ?? new BigNumber('0')
			this.stableVolume.push(sVol.toPrecision(18))
			this.powerVolume.push(pVol.toPrecision(18))
			const unit: BigNumber = sVol.dividedBy(pVol.decimalPlaces(2))
			this.unitPrice.push(unit.toPrecision(3))
			minDate = minDate.add(1, 'days')
		}
		if (document.getElementById('chart_1') !== null) {
			const targetCanvas = document.getElementById('chart_1') as HTMLCanvasElement
			this.drawSample(targetCanvas)
		}
	}

	private firstVol (v1: Volume[], v2: Volume[]): Volume[] {
		if (v1.length > 0 && v2.length > 0) {
			return moment(v1[0].at).isBefore(moment(v2[0].at)) ? v1 : v2
		} else if (v1.length > 0) {
			return v1
		} else {
			return v2
		}
	}

	@Watch('$store.state.darkMode')
	switchDarkMode (): void {
		if (document.getElementById('chart_1') !== null) {
			const targetCanvas = document.getElementById('chart_1') as HTMLCanvasElement
			// eslint-disable-next-line no-unused-expressions
			Chart.getChart('chart_1')?.destroy()
			this.drawSample(targetCanvas)
		}
	}

	private drawSample (canvas: HTMLCanvasElement) {
		const chart = new Chart(canvas, {
			type: 'line',
			data: {
				labels: this.labels,
				datasets: [{
					label: this.$t('chart.averagePrice') as string,
					data: this.unitPrice,
					// hide the color under the line
					backgroundColor: this.$store.state.darkMode ? 'white' : 'grey',
					// line color
					borderColor: this.$store.state.darkMode ? 'white' : 'grey',
					pointStyle: 'round',
					borderWidth: 2,
					tension: 0.25,
					type: 'line',
					yAxisID: 'y2',
					spanGaps: true,
				}, {
					label: this.$t('chart.powerVolume') as string,
					data: this.powerVolume,
					// hide the color under the line
					backgroundColor: this.cpRed,
					// line color
					borderColor: this.cpRed,
					borderWidth: 1.2,
					type: 'bar',
					yAxisID: 'y1',
				}, {
					label: this.$t('chart.stableVolume') as string,
					data: this.stableVolume,
					// hide the color under the line
					backgroundColor: this.cpBlueLight,
					// line color
					borderColor: this.cpBlueLight,
					borderWidth: 1.2,
					type: 'bar',
					yAxisID: 'y1',
				}],
			},
			options: {
				interaction: {
					intersect: true,
					mode: 'index',
					axis: 'y',
				},
				plugins: {
					legend: {
						display: true,
						labels: {
							color: this.$store.state.darkMode ? 'white' : 'grey',
						},
					},
					title: {
						display: true,
						color: this.$store.state.darkMode ? 'white' : 'grey',
						text: this.$t('chart.title') as string,
					},
				},
				responsive: true,
				aspectRatio: this.$vuetify.breakpoint.mobile ? 1 : 2,
				scales: {
					x: {
						grid: {
							borderColor: this.$store.state.darkMode ? 'white' : 'grey',
							tickColor: this.$store.state.darkMode ? 'white' : 'grey',
						},
						ticks: {
							color: this.$store.state.darkMode ? 'white' : 'grey',
						},
					},
					y1: {
						beginAtZero: true,
						grid: {
							borderColor: this.$store.state.darkMode ? 'white' : 'grey',
							display: false,
							tickColor: this.$store.state.darkMode ? 'white' : 'grey',
						},
						position: 'left',
						ticks: {
							color: this.$store.state.darkMode ? 'white' : 'grey',
						},
					},
					y2: {
						beginAtZero: true,
						grid: {
							borderColor: this.$store.state.darkMode ? 'white' : 'grey',
							display: false,
							tickColor: this.$store.state.darkMode ? 'white' : 'grey',
						},
						position: 'right',
						ticks: {
							color: this.$store.state.darkMode ? 'white' : 'grey',
						},
					},
				},
			},
		})
	}
}
