import type { AlertDialogOptions, AlertDialogOptionsWithButton, AlertDialogRawOptions, Currency, Network } from '@/types'
import { Rate } from '@/models/Rate'
import { mdiBank, mdiCharity, mdiCreditCard, mdiEqualizer, mdiEthereum, mdiHelpRhombusOutline, mdiTransfer } from '@mdi/js'
import { BigNumber } from 'bignumber.js'
import moment from 'moment-timezone'
import { path as binanceIcon } from 'simple-icons/icons/binance'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Account } from '@/models/Account'
import {AlertDialogOptionsWithButtonAndLink, TokenType} from '@/types'
import { TermDepositItem, TradePair } from '@/clients/cpinblocks'
import {Conf, CurrencyType} from '@/models/Conf'

export const eventBus = new Vue()

export const binanceIconGlobal = binanceIcon

export function formatDate (date: Date, i18n: VueI18n): string {
	return moment(date)
		.tz(moment.tz.guess())
		.locale(i18n.locale)
		.format('LLL')
}

export function zonedISO8601 (date: Date): string {
	return moment(date)
		.tz(moment.tz.guess())
		.format()
}

export function formatMoney (i18n: VueI18n, amount: BigNumber, currency: Currency, displaySign = false): string {
	const formattedAmount = amount.toFormat({
		prefix: displaySign && amount.isGreaterThanOrEqualTo('0') ? '+' : undefined,
		groupSize: 3,
		groupSeparator: '\u00a0',
		decimalSeparator: '.',
	})
	return formattedAmount + ' ' + currency
}

export function formatFixedDecimals (i18n: VueI18n, amount: BigNumber, precision: number): string {
	BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
	return amount.toFormat(precision, {
		groupSize: 3,
		groupSeparator: '\u00a0',
		decimalSeparator: '.',
	})
}

// 1.0000000 should be returned as 1 and 1.02000 as 1.02
// not working, use formatFixedDecimalsNoUselessZero instead
export function formatFixedDecimalsNice (i18n: VueI18n, amount: BigNumber, precision: number): string {
	BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
	let result = amount.toFormat(precision, {
		groupSize: 3,
		groupSeparator: '\u00a0',
		decimalSeparator: '.',
	})
	if (result.indexOf('.') === -1) {
		return result
	} else {
		result.replace(/\.?0*$/, '')
	}
	return result
}

export function formatFixedDecimalsNoUselessZero (i18n: VueI18n, amount: BigNumber, precision: number): string {
	// to avoid e-x notation
	BigNumber.config({ EXPONENTIAL_AT: 99999 })
	const value = amount.toString().split('')
	const [_, floats = ''] = formatFixedDecimalsNice(i18n, amount, precision)
	for (let i = floats.length - 1; i >= 0; i--) {
		if (floats[i] !== '0') break
	}
	return value.filter(numbers => numbers).join('')
}

export function formattedToBigNumber (formatted: string | null | undefined, precision: number): BigNumber {
	return formatted
		? new BigNumber(formatted.replaceAll(/[^\d]/g, '')).times(new BigNumber(10).exponentiatedBy(-precision))
		: new BigNumber('NaN')
}

export function parsedValueToBigNumber (value: string): string {
	const parsed = value.match(/[0-9]|[.]|[0]/gm) ?? []
	return parsed.join('')
}

export function convertValueToBigNumber (value: string): BigNumber {
	return new BigNumber(parsedValueToBigNumber(value))
}

export function networkIcon (network: Network): string {
	if (!network) {
		return mdiHelpRhombusOutline
	}
	switch (network) {
		case 'BANK_TRANSFER':
			return mdiBank
		case 'BINANCE':
			return binanceIcon
		case 'CREDIT_CARD':
			return mdiCreditCard
		case 'ETHEREUM':
			return mdiEthereum
		case 'COMPENSATION':
			return mdiEqualizer
		case 'SPONSORSHIP':
			return mdiCharity
		case 'INTERNAL':
			return mdiTransfer
		case 'INBLOCKS':
			return mdiTransfer
		default:
			// default to interrogation point
			return mdiHelpRhombusOutline
	}
}

export function env (key: string): string {
	if (key in process.env) {
		return process.env[key] as string
	} else {
		throw new Error(`Environment variable ${key} isn't set.`)
	}
}

export function appEnv (): string {
	return env('VUE_APP_ENV')
}

export function isStaging (): boolean {
	return appEnv() === 'staging'
}

export function isTestnet (): boolean {
	return appEnv() === 'testnet'
}

export function isProd (): boolean {
	return appEnv() === 'prod'
}

export function envInt (key: string): number {
	const value = env(key)
	if (!value.match(/^-?\d+$/)) {
		throw new Error(`Environment variable ${key} isn't an integer.`)
	}
	return parseInt(value, 10)
}

export function envBool (key: string): boolean {
	return (process.env[key] ?? 'false').toLowerCase() === 'true'
}

export function convert (amount: BigNumber, from: Currency, to: Currency, rates: Rate[]): BigNumber | null {
	return convertRec(amount, from, to, rates, new Array(rates.length).fill(false), 1).result

	function convertRec (amount: BigNumber, from: Currency, to: Currency, rates: Rate[], flags: boolean[], steps: number): { result: BigNumber | null, steps: number } {
		let result: BigNumber | null = null
		let smallestSteps = Infinity

		for (let i = 0; i < rates.length; i++) {
			if (flags[i]) {
				continue
			}

			let nextCurrency: Currency
			let nextAmount: BigNumber

			if (rates[i].currencyFrom === from && rates[i].amountFrom.eq(amount)) {
				nextCurrency = rates[i].currencyTo
				nextAmount = rates[i].amountTo
			} else if (rates[i].currencyTo === from && rates[i].amountTo.eq(amount)) {
				nextCurrency = rates[i].currencyFrom
				nextAmount = rates[i].amountFrom
			} else {
				continue
			}

			if (nextCurrency === to) {
				result = nextAmount
				smallestSteps = steps
				break
			}

			flags[i] = true
			const subCall = convertRec(nextAmount, nextCurrency, to, rates, flags, steps + 1)
			if (subCall.result && subCall.steps < smallestSteps) {
				result = subCall.result
				smallestSteps = subCall.steps
			}
			flags[i] = false
		}

		return {
			result: result,
			steps: smallestSteps,
		}
	}
}

export function alertWarn (messageKey: string, reason?: string): void {
	eventBus.$emit('alert', {
		type: 'warning',
		messageKey: messageKey,
		reason: reason,
	} as AlertDialogOptions)
}

export function alertRawError (message: string): void {
	eventBus.$emit('alertRaw', {
		type: 'error',
		message: message,
	} as AlertDialogRawOptions)
}

export function alertError (messageKey: string, reason?: string): void {
	eventBus.$emit('alert', {
		type: 'error',
		messageKey: messageKey,
		reason: reason,
	} as AlertDialogOptions)
}

export function alertErrorWithButton (messageKey: string, buttonLabel: string): void {
	eventBus.$emit('alertWithButton', {
		type: 'error',
		messageKey: messageKey,
		buttonLabel: buttonLabel,
	} as AlertDialogOptionsWithButton)
}

export function alertErrorWithButtonAndLink (messageKey: string, buttonLabel: string, buttonLink: string): void {
	eventBus.$emit('alertWithButtonAndLink', {
		type: 'error',
		messageKey: messageKey,
		buttonLabel: buttonLabel,
		buttonLink: buttonLink,
	} as AlertDialogOptionsWithButtonAndLink)
}

export function alertFatalMessage (message: string): void {
	eventBus.$emit('alert', {
		type: 'fatal',
		message: message,
	} as AlertDialogOptions)
}

export class Token {
	currency: Currency | null = null
	types: TokenType[] = []
}

export class TokenList {
	tokens: Token[] = []

	public TokenList() {
		this.tokens = []
	}

	addCurrencyType(currency: Currency, type: TokenType) {
		for (let t of this.tokens) {
			if (t.currency && t.currency === currency) {
				if (!t.types) {
					t.types = []
				}
				t.types.push(type)
				t.types = [ ...new Set(t.types) ].sort()
				return
			}
		}
		this.tokens.push({ currency, types: [ type ]})
		this.tokens.sort((a: Token, b: Token) => a.currency && b.currency ? a.currency.localeCompare(b.currency) : 1)
	}
}

export function guessAllTokensType(conf: Conf, accounts: Account[], spotPairs: TradePair[], termDeposits: TermDepositItem[]): TokenList {
	const result = new TokenList()
	if (accounts) {
		for (const a of accounts) {
			result.addCurrencyType(a.currency as Currency, a.type as TokenType)
		}
	}
	if (spotPairs) {
		for (const s of spotPairs) {
			result.addCurrencyType(s.productCurrency, s.productType)
			result.addCurrencyType(s.unitPriceCurrency, s.unitPriceType)
		}
	}
	if (termDeposits) {
		for (const t of termDeposits) {
			result.addCurrencyType(t.conf.currency, t.conf.type)
		}
	}
	if (conf) {
		for (const b of conf.blockchains) {
			for (const c of b.contracts) {
				result.addCurrencyType(c.symbol, 'MAIN')
			}
		}
		if (conf.explorable) {
			for (const c of conf.explorable) {
				result.addCurrencyType(c as Currency, 'MAIN')
			}
		}
		if (conf.transferable) {
			for (const o of conf.transferable) {
				result.addCurrencyType(o.currency, o.type)
			}
		}
		if (conf.internalTransferable) {
			for (const o of conf.internalTransferable) {
				if (o && o.allowedCurrencies) {
					for (const c of o.allowedCurrencies) {
						result.addCurrencyType(c as Currency, o.fromType as TokenType)
						result.addCurrencyType(c as Currency, o.toType as TokenType)
					}
				}
			}
		}
		if (conf.marketplaceFTPairs) {
			for (const o of conf.marketplaceFTPairs) {
				result.addCurrencyType(o.currency, o.type)
			}
		}
		if (conf.staking) {
			for (const s of conf.staking) {
				result.addCurrencyType(s.currency, s.type)
			}
		}
	}
	return result
}

export function spotCounterparts(spotPairs: TradePair[], currency: Currency | null, type: TokenType): CurrencyType[] {
	let result: CurrencyType[] = []
	if (currency === null) {
		return result
	}
	if (spotPairs) {
		for (const sp of spotPairs) {
			if (sp.productCurrency === currency && sp.productType === type) {
				result.push({ currency: sp.unitPriceCurrency, type: sp.unitPriceType })
			} else if (sp.unitPriceCurrency === currency && sp.unitPriceType === type) {
				result.push({ currency: sp.productCurrency, type: sp.productType })
			}
		}
	}
	return result
}

export function hasTermDeposit(termDeposit: TermDepositItem[], currency: Currency | null, type: TokenType): boolean {
	if (currency === null) {
		return false
	}
	if (termDeposit) {
		for (const td of termDeposit) {
			if (td.conf.currency === currency && td.conf.type === type) {
				return true
			}
		}
	}
	return false
}

export class AccountCharacteristics {
	address: string | null = null
	blockchain: string | null = null
	convertible: CurrencyType[] = []
	currency: string = ''
	deposit: boolean | null = null
	explorable: boolean | null = null
	otc: CurrencyType[] = []
	spot: CurrencyType[] = []
	staking: boolean | null = null
	termDeposit: boolean | null = null
	transferable: boolean | string[] | null = null
	type: string | null = null
	withdraw: boolean | null = null
}

export function getGlobalConfInfo(conf: Conf, spotPairs: TradePair[], termDeposits: TermDepositItem[], token: Token): AccountCharacteristics[] {
	const result: AccountCharacteristics[] = []
	for (const type of token.types) {
		const blockchain = conf?.getBlockchain(token.currency)
		result.push({
			address: conf?.getScanAddress(blockchain, token.currency, type),
			blockchain,
			convertible: conf?.isConvertible(token.currency, type),
			currency: token.currency ? token.currency : '',
			deposit: conf?.isDepositEnabled(blockchain, token.currency, type),
			explorable: conf?.isExplorable(token.currency, type),
			otc: conf?.otcPairs(token.currency, type),
			spot: spotPairs ? spotCounterparts(spotPairs, token.currency, type) : [],
			staking: conf?.hasStaking(token.currency, type),
			termDeposit: hasTermDeposit(termDeposits, token.currency, type),
			transferable: conf?.isTransferable(token.currency, type),
			type: type,
			withdraw: conf?.isWithdrawEnabled(blockchain, token.currency, type),
		})
	}
	return result
}

export function customDecodeJWT (jwt: string): object | null {
    const split = jwt.split('.')
	if (split.length < 2) {
		return null
	}

	let obj1 = JSON.parse(atob(split[0]))
	let obj2 = JSON.parse(atob(split[1]))

	return Object.assign({}, obj1, obj2)
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: any, ...sources: any): any {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}
