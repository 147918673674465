import { jsonArrayMember, jsonMember, jsonObject, stringMapper, TypedJSON } from '@/typedjson'
import type { Currency, Network } from '@/types'
import { convert } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { Offer } from './Offer'
import { Rate } from './Rate'
import type { CurrencyTypeValueTuple } from '@/clients/cpinblocks'

export type OperationType = 'BUY' | 'BUYBACK' | 'COMPENSATION' | 'COMPENSATION_RELEASE' | 'ICO_RELEASE' | 'OTC' | 'OPEN_CHEST' | 'REFUND' | 'RESELL' | 'SPONSORSHIP' | 'STAKING' | 'TRANSFER' | 'TRANSFER_IN' | 'TRANSFER_OUT' | 'UNMINT' | 'WITHDRAW'

export interface OperationDetails {
	network?: Network
	operationAmount: BigNumber
	operationCurrency: Currency
	amountFrom?: BigNumber
	currencyFrom?: Currency
	amountTo?: BigNumber
	currencyTo?: Currency
	referenceAmount?: BigNumber | null
	sponsor?: string
	referral?: string
	release?: number
	releases?: number
	label?: string
	transactionHash?: string
	stakingId?: string
	amount?: string
	actionNet?: string
	action?: string
	role?: string
	currency?: string
	type?: string
	collection?: string
	tokenId?: string
	from?: string
	buybackId?: string
	to?: string
	token?: string
}

@jsonObject
export abstract class Operation<Type extends OperationType = OperationType> {
	@jsonMember id!: string
	@jsonMember type!: Type
	@jsonMember at!: Date
	@jsonMember quantity!: BigNumber
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember details?: string

	abstract getNetwork (allOperations: { [id: string]: Operation }): Network
	abstract getDetails (referenceCurrency: Currency, allOperations: { [id: string]: Operation }): OperationDetails
}

@jsonObject
export class OperationBuyMeta {
	@jsonMember(stringMapper) currencyFrom!: Currency
	@jsonMember(stringMapper) network!: Network
	@jsonMember compensationOperationId?: string
	@jsonMember refundOperationId?: string
	@jsonArrayMember(Rate) rates!: Rate[]
}
@jsonObject
export class OperationBuy extends Operation<'BUY'> {
	@jsonMember meta!: OperationBuyMeta

	getNetwork (): Network {
		return this.meta.network
	}

	getDetails (referenceCurrency: Currency): OperationDetails {
		if (this.meta.network === 'INBLOCKS') {
			const amountFrom = this.meta.rates[0].amountFrom
			if (amountFrom === null) {
				throw new Error('failed to compute amountFrom')
			}
			return {
				network: this.meta.network,
				operationAmount: this.quantity,
				operationCurrency: this.currency,
				amountFrom: amountFrom,
				currencyFrom: this.meta.rates[0].currencyFrom,
				amountTo: this.meta.rates[0].amountTo,
				currencyTo: this.meta.rates[0].currencyTo,
				referenceAmount: convert(amountFrom, this.meta.currencyFrom, referenceCurrency, this.meta.rates),
			}
		} else {
			const amountFrom = convert(this.quantity, this.currency, this.meta.currencyFrom, this.meta.rates)
			if (amountFrom === null) {
				throw new Error('failed to compute amountFrom')
			}
			return {
				network: this.meta.network,
				operationAmount: this.quantity,
				operationCurrency: this.currency,
				amountFrom: amountFrom,
				currencyFrom: this.meta.currencyFrom,
				amountTo: this.quantity,
				currencyTo: this.currency,
				referenceAmount: convert(amountFrom, this.meta.currencyFrom, referenceCurrency, this.meta.rates),
			}
		}
	}
}

@jsonObject
export class OperationOTCMeta {
	@jsonMember deal!: Offer
}
@jsonObject
export class OperationOTC extends Operation<'OTC'> {
	@jsonMember meta!: OperationOTCMeta

	getNetwork (): Network {
		return this.meta.deal.network
	}

	getDetails (): OperationDetails {
		return {
			network: this.meta.deal.network,
			operationAmount: this.meta.deal.fee ? this.quantity.plus(this.meta.deal.fee.negated()) : this.quantity,
			operationCurrency: this.currency,
			...this.meta.deal.rate,
			referenceAmount: null,
		}
	}
}

@jsonObject
export class OperationRefundMeta {
	@jsonMember operationId!: string
}
@jsonObject
export class OperationRefund extends Operation<'REFUND'> {
	@jsonMember meta!: OperationRefundMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return allOperations[this.meta.operationId].getNetwork(allOperations)
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			...allOperations[this.meta.operationId].getDetails(referenceCurrency, allOperations),
			operationAmount: this.quantity,
			operationCurrency: this.currency,
		}
	}
}

@jsonObject
export class OperationCompensationMeta {
	@jsonMember compensatedOperationId!: string
}
@jsonObject
export class OperationCompensation extends Operation<'COMPENSATION'> {
	@jsonMember meta!: OperationCompensationMeta

	getNetwork (): Network {
		return 'COMPENSATION'
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			...allOperations[this.meta.compensatedOperationId].getDetails(referenceCurrency, allOperations),
			operationAmount: this.quantity,
			operationCurrency: this.currency,
		}
	}
}

@jsonObject
export class OperationCompensationReleaseMeta {
	@jsonMember release!: number
	@jsonMember releases!: number
}
@jsonObject
export class OperationCompensationRelease extends Operation<'COMPENSATION_RELEASE'> {
	@jsonMember meta!: OperationCompensationReleaseMeta

	getNetwork (): Network {
		return 'COMPENSATION'
	}

	getDetails (): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			release: this.meta.release,
			releases: this.meta.releases,
		}
	}
}

@jsonObject
export class OperationIcoReleaseMeta {
	@jsonMember release!: number
	@jsonMember releases!: number
}
@jsonObject
export class OperationIcoRelease extends Operation<'ICO_RELEASE'> {
	@jsonMember meta!: OperationIcoReleaseMeta

	getNetwork (): Network {
		return 'COMPENSATION'
	}

	getDetails (): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			release: this.meta.release,
			releases: this.meta.releases,
		}
	}
}

@jsonObject
export class OperationOpenChest extends Operation<'OPEN_CHEST'> {
	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return 'INTERNAL'
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
		}
	}
}

@jsonObject
export class OperationSponsorshipMeta {
	@jsonMember sponsorUserId!: string
	@jsonMember referralUserId!: string
}
@jsonObject
export class OperationSponsorship extends Operation<'SPONSORSHIP'> {
	@jsonMember meta!: OperationSponsorshipMeta

	getNetwork (): Network {
		return 'SPONSORSHIP'
	}

	getDetails (): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			sponsor: this.meta.sponsorUserId,
			referral: this.meta.referralUserId,
		}
	}
}

@jsonObject
export class OperationStakingMeta {
	@jsonMember stakingId!: string
	@jsonMember amount!: string
	@jsonMember actionNet!: string
	@jsonMember action!: string
}
@jsonObject
export class OperationStaking extends Operation<'STAKING'> {
	@jsonMember meta!: OperationStakingMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return 'INTERNAL'
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			action: this.meta.action,
			stakingId: this.meta.stakingId,
		}
	}
}

@jsonObject
export class OperationTransferMeta {
	@jsonMember label!: string
}
@jsonObject
export class OperationTransfer extends Operation<'TRANSFER'> {
	@jsonMember meta!: OperationTransferMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return 'INTERNAL'
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			label: this.meta.label,
		}
	}
}

@jsonObject
export class OperationTransferOutMeta {
	@jsonMember to!: string
	@jsonMember(stringMapper) network!: Network
	@jsonMember transactionHash?: string
}
@jsonObject
export class OperationTransferOut extends Operation<'TRANSFER_OUT'> {
	@jsonMember meta!: OperationTransferOutMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return this.meta.network
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			transactionHash: this.meta.transactionHash,
		}
	}
}

@jsonObject
export class OperationResellMeta {
	@jsonMember role!: string
	@jsonMember amount!: string
	@jsonMember currency!: string
	@jsonMember type!: string
}
@jsonObject
export class OperationResell extends Operation<'RESELL'> {
	@jsonMember meta!: OperationResellMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return 'INTERNAL'
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			role: this.meta.role,
			amount: this.meta.amount,
			currency: this.meta.currency,
			type: this.meta.type,
		}
	}
}

@jsonObject
export class OperationUnmintMeta {
	@jsonMember collection!: string
	@jsonMember tokenId!: string
}
@jsonObject
export class OperationUnmint extends Operation<'UNMINT'> {
	@jsonMember meta!: OperationUnmintMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return 'INTERNAL'
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			collection: this.meta?.collection,
			tokenId: this.meta?.tokenId,
		}
	}
}

@jsonObject
export class OperationTransferInMeta {
	@jsonMember from!: string
	@jsonMember(stringMapper) network!: Network
	@jsonMember transactionHash?: string
}
@jsonObject
export class OperationTransferIn extends Operation<'TRANSFER_IN'> {
	@jsonMember meta!: OperationTransferInMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return this.meta.network
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			transactionHash: this.meta.transactionHash,
			from: this.meta.from,
		}
	}
}

@jsonObject
export class OperationBuybackMeta {
	@jsonMember rank!: number
	@jsonMember action!: string
	@jsonMember buybackId?: string
}
@jsonObject
export class OperationBuyback extends Operation<'BUYBACK'> {
	@jsonMember meta!: OperationBuybackMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return 'INTERNAL'
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			buybackId: this.meta.buybackId,
		}
	}
}

@jsonObject
export class OperationWithdrawMeta {
	@jsonMember amount!: string
	@jsonMember fee!: CurrencyTypeValueTuple
	@jsonMember(stringMapper) network!: Network
	@jsonMember token!: string
	@jsonMember tokenId!: string
	@jsonMember transactionHash?: string
	@jsonMember to!: string
}
@jsonObject
export class OperationWithdraw extends Operation<'WITHDRAW'> {
	@jsonMember meta!: OperationWithdrawMeta

	getNetwork (allOperations: { [id: string]: Operation }): Network {
		return this.meta.network
	}

	getDetails (referenceCurrency: Currency, allOperations: { [p: string]: Operation }): OperationDetails {
		return {
			amount: this.meta.amount,
			operationAmount: this.quantity,
			operationCurrency: this.currency,
			token: this.meta.token,
			transactionHash: this.meta.transactionHash,
		}
	}
}

TypedJSON.mapType(Operation, {
	deserializer: v => {
		if (!v) {
			return v
		}

		if (!v.type) {
			console.log('ERROR, type undefined')
			return
		}

		switch (v.type as OperationType) {
			case 'BUY':
				return TypedJSON.parse(v, OperationBuy)
			case 'BUYBACK':
				return TypedJSON.parse(v, OperationBuyback)
			case 'COMPENSATION':
				return TypedJSON.parse(v, OperationCompensation)
			case 'COMPENSATION_RELEASE':
				return TypedJSON.parse(v, OperationCompensationRelease)
			case 'ICO_RELEASE':
				return TypedJSON.parse(v, OperationIcoRelease)
			case 'OPEN_CHEST':
				return TypedJSON.parse(v, OperationOpenChest)
			case 'OTC':
				return TypedJSON.parse(v, OperationOTC)
			case 'REFUND':
				return TypedJSON.parse(v, OperationRefund)
			case 'RESELL':
				return TypedJSON.parse(v, OperationResell)
			case 'SPONSORSHIP':
				return TypedJSON.parse(v, OperationSponsorship)
			case 'STAKING':
				return TypedJSON.parse(v, OperationStaking)
			case 'TRANSFER':
				return TypedJSON.parse(v, OperationTransfer)
			case 'TRANSFER_OUT':
				return TypedJSON.parse(v, OperationTransferOut)
			case 'TRANSFER_IN':
				return TypedJSON.parse(v, OperationTransferIn)
			case 'UNMINT':
				return TypedJSON.parse(v, OperationUnmint)
			case 'WITHDRAW':
				return TypedJSON.parse(v, OperationWithdraw)
			default:
				throw new Error(`unexpected operation type: ${v.type}`)
		}
	},
})
