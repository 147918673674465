import { BigNumber } from 'bignumber.js'
import 'reflect-metadata'
import { TypedJSON } from 'typedjson'
import { MappedTypeConverters } from 'typedjson/src/parser'

TypedJSON.mapType(BigNumber, {
	serializer: v => v?.toString() ?? null,
	deserializer: v => typeof v === 'string' ? new BigNumber(v) : null,
})

TypedJSON.mapType(Date, {
	serializer: v => v?.toISOString() ?? null,
	deserializer: v => typeof v === 'string' ? new Date(v) : null,
})

/** Explicitly indicate to typedjson that the field must be parsed as a string. */
export const stringMapper: MappedTypeConverters<string> = {
	serializer: v => v,
	deserializer: v => v,
}

export * from 'typedjson'
