import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { en, fr } from 'vuetify/src/locale'

Vue.use(Vuetify)

const ib1 = '#13293D'
const ib2 = '#5EC9C5'
const grey = '#646464'

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg',
	},
	lang: {
		locales: {
			en,
			fr,
		},
		current: 'en',
	},
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: ib1,
				secondary: ib2,
				error: '#FF0000',
			},
			dark: {
				primary: grey,
				secondary: ib2,
				error: '#FF0000',
			},
		},
	},
})
