









































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import CodeInput from '@/components/CodeInput.vue'
import {finalize2faSetup, initialize2faSetup, isAxiosError, refreshOwner} from '@/clients/cpinblocks'
import { alertError } from '@/utils'
import {AxiosError, AxiosResponse} from "axios";

@Component({
	components: { CodeInput },
})

export default class ConfigQR extends Vue {
	@Prop()
	id!: string

	ibexLoading = false
	QRCode = ''
	accountId = ''
	secretKey = ''
	codeInputIBEx = 0
	secretKeyFieldType = 'password'
	step = 0
	blankImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='
	ibex2FAStatus: string | null = null

	async mounted (): Promise<void> {
		if (this.$store.state.owner.enabled2fa === true && this.$store.state.owner.locked2fa == false) {
			this.step = 3
		} else if (this.$store.state.owner.locked2fa == true) {
			this.step = 3
		} else {
			this.step = 1
			this.ibexLoading = true
			await this.displayQRandSecretKey()
			this.ibexLoading = false
		}
	}

	copyToClipboard (text: string): void {
		navigator.clipboard.writeText(text)
	}

	toggleSecretKeyFieldType (): void {
		this.secretKeyFieldType = this.secretKeyFieldType === 'password' ? 'text' : 'password'
	}

	async displayQRandSecretKey (): Promise<void> {
		try {
			const response = await initialize2faSetup(this.$keycloak.token!)
			this.QRCode = 'data:image/png;base64,' + response?.imageB64
			this.secretKey = '' + response?.key
		} catch (error) {
			alertError(error)
		}
	}

	async setIBEXCode (code: string): Promise<void> {
		this.ibexLoading = true
		try {
			await finalize2faSetup(this.$store.state.jwt, code)
			this.ibex2FAStatus = 'success'
			await refreshOwner(this.$store.state.jwt, this.$store)
			setInterval(async () => {
				await this.$emit('closeWindow')
			}, 2500)
		} catch (err: unknown) {
			if (!isAxiosError(err)) {
				return
			}
			this.ibex2FAStatus = 'failure'
			this.codeInputIBEx++
			await refreshOwner(this.$store.state.jwt, this.$store)
			if (err.response?.status === 403 && err.response?.data?.error === 'MAX_ATTEMPTS_REACHED') {
				this.step=3
				setInterval(async () => {
					await this.$emit('closeWindow')
				}, 2500)
			}
		}
		this.ibexLoading = false
	}
}

