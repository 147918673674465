import { jsonMember, jsonObject, stringMapper } from '@/typedjson'
import { BigNumber } from 'bignumber.js'

@jsonObject
export class Staking {
	@jsonMember version!: number
	@jsonMember at!: Date
	@jsonMember globalAPR!: BigNumber
	@jsonMember globalStaked!: BigNumber
	@jsonMember globalStakers!: number
	@jsonMember staked!: BigNumber
	@jsonMember rewardsPerSec!: BigNumber
	@jsonMember rewards!: BigNumber
	@jsonMember totalRewards!: BigNumber
}
