import Keycloak, { KeycloakOnLoad } from 'keycloak-js'
import { Store } from 'vuex'
import { StoreState } from '@/store'
import _Vue from 'vue'
import { env } from '@/utils'

export const initOptions = {
	url: env('VUE_APP_IBEX_AUTH_ENDPOINT'),
	realm: 'inblocks-ibex',
	clientId: 'ibex-ui',
	onLoad: 'login-required' as KeycloakOnLoad,
}

export function KeycloakPlugin (Vue: typeof _Vue, store: Store<StoreState>): void {
	Vue.prototype.$keycloak = Keycloak(initOptions)
}
