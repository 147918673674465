import { jsonMember, jsonObject } from '@/typedjson'
import { jsonArrayMember } from 'typedjson'
import {
	Beneficial,
	CompanyIdentity,
	CompanyInformation,
	Identity,
	InvestorProfessionalProfile,
	InvestorProfile,
} from '@/models/KYInformation'
import { DocumentVerificationStatus, VerificationFactor } from '@/models/KYState'
import { Address } from '@/clients/cpinblocks'
import { BigNumber } from 'bignumber.js'

@jsonObject
export class BCAdresses {
	addresses?: Address[]
}

@jsonObject
export class ProviderId {
	@jsonMember provider!: string
	@jsonMember id!: string
}

export class taxInfo {
	@jsonMember country!: string
	@jsonMember taxPayerId!: string
}

@jsonObject
export class RetailInfo {
	@jsonMember kycLevel?: number
	@jsonMember investorProfile?: InvestorProfile
}

@jsonObject
export class InstitutionalInfo {
	@jsonMember companyIdentity?: CompanyIdentity
	@jsonMember companyInformation?: CompanyInformation
	@jsonMember investorProfessionalProfile?: InvestorProfessionalProfile
	@jsonMember kybLevel?: number
	@jsonArrayMember(Beneficial) beneficialOwners?: Beneficial[]
}

export type LockStatus = 'NO' | 'SOFT' | 'HARD'

@jsonObject
export class Iban {
	@jsonMember value!: string
	@jsonMember accountHolderFullName?: string
	@jsonMember label!: string
	@jsonMember blocked!: boolean
}

@jsonObject
export class Goat {
	@jsonMember id!: string
	@jsonMember name!: string
	@jsonMember lockedEUR!: BigNumber
	@jsonMember lockedPOWER!: BigNumber
}

@jsonObject
export class User {
	@jsonMember ownerId!: string
	@jsonArrayMember(ProviderId) userIds!: ProviderId[]
	@jsonMember(BCAdresses) ethereum?: BCAdresses
	@jsonMember identity!: Identity
	@jsonMember locked!: LockStatus
	@jsonMember enabled2fa!: boolean
	@jsonMember locked2fa!: boolean
	@jsonMember(Date) createdAt!: Date
	@jsonMember(Date) updatedAt!: Date
	@jsonMember idDocVerificationResults?: string
	@jsonMember idVerificationStatus?: DocumentVerificationStatus
	@jsonArrayMember(String) pendingVerificationFactors!: VerificationFactor[]
	@jsonArrayMember(String) acceptedVerificationFactors!: VerificationFactor[]
	@jsonMember pendingValidationSince?: string
	@jsonMember pendingValidation!: boolean
	@jsonMember rejectedValidation!: boolean
	@jsonMember rejectedValidationAt?: string
	@jsonMember maxIdVerificationAttemptsExceeded!: boolean
	@jsonArrayMember(Iban) ibans?: Iban[]
	@jsonMember elrondAddress?: string
	@jsonMember retail?: RetailInfo
	@jsonMember institutional?: InstitutionalInfo
	@jsonMember goat?: Goat

	constructor (owner: User | null) {
		Object.assign(this, owner)
	}

	get kyLevel (): number {
		return (this.retail?.kycLevel !== undefined) ? this.retail?.kycLevel : (this.institutional?.kybLevel !== undefined) ? this.institutional?.kybLevel : 0
	}

	get canHaveLevel2 (): boolean {
		return this.kyLevel === 1 && !this.pendingVerificationFactors.includes(VerificationFactor.COMPANY_INFO) && !this.pendingVerificationFactors.includes(VerificationFactor.BENEF_OWNERS_INFO)
	}

	get verificationFactors (): VerificationFactor[] {
		return [...this.pendingVerificationFactors, ...this.acceptedVerificationFactors]
	}

	isCorporate (): boolean {
		return this.institutional !== undefined
	}

	getCivicID (): string | undefined {
		if (this.userIds) {
			for (const uid of this.userIds) {
				if (uid.provider === 'civicpower') {
					return uid.id
				}
			}
		}
		return undefined
	}
}
