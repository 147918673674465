import { jsonMember, jsonObject, stringMapper } from '@/typedjson'
import BigNumber from 'bignumber.js'
import type { Currency, TokenType } from '@/types'

// FTMPOffer = Fungible Token MarketPlace offer
// also used in STO secondary private market (STO OTC)
@jsonObject
export class FTMPOffer {
	@jsonMember id?: string
	@jsonMember createdAt?: Date
	@jsonMember seller?: string
	@jsonMember splittable?: boolean
	@jsonMember productAmount!: BigNumber
	@jsonMember(stringMapper) productCurrency?: Currency
	@jsonMember(stringMapper) productType?: TokenType
	@jsonMember unitPriceAmount!: BigNumber
	@jsonMember(stringMapper) unitPriceCurrency?: Currency
	@jsonMember(stringMapper) unitPriceType?: TokenType
	@jsonMember buyer?: string
	// @jsonMember totalPrice?: BigNumber
}
