











import { mdiContentCopy, mdiCheckCircleOutline } from '@mdi/js'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ClipboardCopy extends Vue {
	@Prop() content!: string

	mdiContentCopy = mdiContentCopy
	mdiCheckCircleOutline = mdiCheckCircleOutline

	hover = false
	copied = false
	animationTimeout: number | null = null

	private get showTooltip (): boolean {
	  return this.hover || this.copied
	}

	private async copy () {
	  await navigator.clipboard.writeText(this.content)
	  this.copied = true
	  if (this.animationTimeout !== null) {
	    window.clearTimeout(this.animationTimeout)
	  }
	  this.animationTimeout = window.setTimeout(() => {
	    this.copied = false
	    this.animationTimeout = null
	  }, 1000)
	  const btn = document.activeElement as HTMLElement | null
	  if (btn) {
	    btn.blur()
	  }
	}
}
