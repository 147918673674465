














































import type {
	AlertDialogOptions,
	AlertDialogOptionsWithButton,
	AlertDialogOptionsWithButtonAndLink,
	AlertDialogRawOptions
} from '@/types'
import { eventBus } from '@/utils'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AlertDialog extends Vue {
	options: AlertDialogOptions | null = null
	optionsWithButton: AlertDialogOptionsWithButton | null = null
	optionsWithButtonAndLink: AlertDialogOptionsWithButtonAndLink | null = null
	optionsRaw: AlertDialogRawOptions | null = null
	show = false

	created (): void {
		eventBus.$on('alert', (options: AlertDialogOptions) => {
			this.show = true
			this.options = options
		})
		eventBus.$on('alertWithButton', (optionsWithButton: AlertDialogOptionsWithButton) => {
			this.show = true
			this.optionsWithButton = optionsWithButton
		})
		eventBus.$on('alertWithButtonAndLink', (optionsWithButtonAndLink: AlertDialogOptionsWithButtonAndLink) => {
			this.show = true
			this.optionsWithButtonAndLink = optionsWithButtonAndLink
		})
		eventBus.$on('alertRaw', (optionsRaw: AlertDialogRawOptions) => {
			this.show = true
			this.optionsRaw = optionsRaw
		})
	}

	private buttonClick (): void {
		this.optionsWithButton = null
		this.show = false
	}

	private buttonClickWithLink (): void {
		window.open(this.optionsWithButtonAndLink?.buttonLink, '_blank')
		this.optionsWithButtonAndLink = null
		this.show = false
	}
}
