import router from '@/router'
import { NavigationGuard } from 'vue-router'
import { initOptions } from './keycloak'

let initialized = false

const KeycloakMiddleware: NavigationGuard = async function (to, from, next) {
	const app = router.app

	if (initialized) {
		return next()
	}

	app.$keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
		if (!auth) {
			// console.log('# 1 User is not authenticated, removing the JWT from the store')
			app.$store.commit('jwt', null)
			window.location.reload()
		} else {
			// console.log('# 2 User is already authenticated ' + keycloak.token)
			app.$store.commit('jwt', app.$keycloak.token)
		}

		// Token Refresh
		setInterval(() => {
			app.$keycloak.updateToken(300).then((refreshed) => {
				if (refreshed) {
					// console.log('#3 Token refreshed')
					app.$store.commit('jwt', app.$keycloak.token)
				} else {
					// console.log('#4 Token not refreshed')
				}
			}).catch(() => {
				// console.log('Failed to refresh token')
			})
		}, 10000)
	}).catch(() => {
		// If the user decline Terms and Conditions
		window.location.reload()
	}).finally(() => {
		initialized = true
		// to force the router and the app to be consistent with each other, whatever that means :-)
		app.$router.push(to.fullPath.split('#')[0])
	})
}

export default KeycloakMiddleware
