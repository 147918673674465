import { jsonArrayMember, jsonMember, jsonObject, stringMapper } from '@/typedjson'
import type { Currency, Network, TokenType } from '@/types'
import { BigNumber } from 'bignumber.js'

@jsonObject
export class Feature {
	@jsonMember enabled!: true
}

@jsonObject
export class Contract {
	@jsonMember address!: string
	@jsonMember decimals!: number
	@jsonMember(stringMapper) symbol!: Currency
	@jsonMember(Feature) buyTransak!: Feature
	@jsonMember(Feature) deposit!: Feature
	@jsonMember(Feature) withdraw!: Feature
}

@jsonObject
export class Blockchain {
	@jsonMember(stringMapper) network!: Network
	@jsonMember chain!: number
	@jsonMember name!: string
	@jsonMember(stringMapper) symbol!: Currency
	@jsonMember precision!: number
	@jsonMember confirmations!: number
	@jsonMember scanUrl!: string
	@jsonArrayMember(String) rpcUrls!: string[]
	@jsonArrayMember(Contract) contracts!: Contract[]

	getCurrencies () : Currency[] {
		const result: Currency[] = []
		for (const c of this.contracts) {
			result.push(c.symbol)
		}
		return result
	}

	getContract (currency: Currency): Contract | null {
		for (const c of this.contracts) {
			if (c.symbol === currency) {
				return c
			}
		}
		return null
	}
}

@jsonObject
export class Template {
	@jsonMember login!: string
	@jsonMember createOffer!: string
	@jsonMember cancelOffer!: string
}

@jsonObject
export class OTCAccount {
	@jsonMember(stringMapper) type!: TokenType
	@jsonMember newBuyer!: boolean
}

@jsonObject
export class OTCConf {
	@jsonMember powerDecimals!: number
	@jsonMember unitPriceDecimals!: number
	@jsonMember minPower!: BigNumber
	@jsonMember expirationDuration!: number
	@jsonMember expirationSecurityDuration!: number
	@jsonArrayMember(OTCAccount) accounts!: OTCAccount[]

	getTradableTokenTypes (): TokenType[] {
		const result: TokenType[] = []
		for (const account of this.accounts) {
			result.push(account.type)
		}
		return result
	}
}

@jsonObject
export class NetworkLabel {
	@jsonMember(stringMapper) network!: Network
	@jsonMember label!: string
}

@jsonObject
export class LocalizedSignature {
	@jsonMember language!: string
	@jsonMember templates!: Template
	@jsonArrayMember(NetworkLabel) networks!: NetworkLabel[]
}

@jsonObject
export class RewardsConf {
	@jsonMember amount!: BigNumber
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) type!: TokenType
	@jsonMember feePercent!: BigNumber
}

@jsonObject
export class StakingConf {
	@jsonMember id!: string
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) type!: TokenType
	@jsonMember startInclusive!: Date
	@jsonMember endExclusive!: Date
	@jsonMember rewards!: RewardsConf
	@jsonMember stakeMin!: BigNumber
	@jsonMember claimRewardsMin!: BigNumber
	@jsonMember autoRewardCompounding!: boolean
}

@jsonObject
export class BuybackConf {
	@jsonMember id!: number
	@jsonMember label!: string
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember pushStartInclusive!: Date
	@jsonMember pushEndExclusive!: Date
	@jsonMember buybackTargetEUR!: BigNumber
	@jsonMember buybackTargetCurrency!: BigNumber
	@jsonMember POWER_ICO!: BigNumber
	@jsonMember POWER_MAIN!: BigNumber
}

@jsonObject
export class CurrencyType {
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) type!: TokenType
}

@jsonObject
export class MarketplaceFTPairConf {
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) type!: TokenType
	@jsonArrayMember(CurrencyType) pairs!: CurrencyType[]
}
@jsonObject
export class TransferableConf {
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) type!: TokenType
	@jsonArrayMember(String) whitelist?: string[]
}

@jsonObject
export class InternalTransferableConf {
	@jsonMember transactionType!: string
	@jsonMember fromType!: string
	@jsonMember toType!: string
	@jsonArrayMember(String) allowedCurrencies?: string[]
}

@jsonObject
export class Conf {
	@jsonMember appUrl!: string
	@jsonMember transferOutPowerFee!: BigNumber
	@jsonArrayMember(Blockchain) blockchains!: Blockchain[]
	@jsonMember otc!: OTCConf
	@jsonArrayMember(LocalizedSignature) signatures!: LocalizedSignature[]
	@jsonArrayMember(StakingConf) staking?: StakingConf[]
	@jsonArrayMember(BuybackConf) buyback?: BuybackConf[]
	@jsonArrayMember(MarketplaceFTPairConf) marketplaceFTPairs?: MarketplaceFTPairConf[]
	@jsonArrayMember(InternalTransferableConf) internalTransferable?: InternalTransferableConf[]
	@jsonArrayMember(TransferableConf) transferable?: TransferableConf[]
	// @jsonArrayMember(Currency) magicBarnStake?: Currency[] // TODO what is the definition here ?
	@jsonArrayMember(String) explorable?: string[]
	@jsonMember weak2FADuration!: number

	getChain (chainId: number | null | undefined): Blockchain | null {
		for (const b of this.blockchains) {
			if (b.chain === chainId) {
				return b
			}
		}
		return null
	}

	getChainIdFromNetwork (network: Network): number | null {
		for (const b of this.blockchains) {
			if (b.network === network) {
				return b.chain
			}
		}
		return null
	}

	getExpirationDuration (): number {
		return this.otc.expirationDuration * 1000
	}

	getExpirationSecurityDuration (): number {
		return this.otc.expirationSecurityDuration * 1000
	}

	getSupportedChainIds (): number[] {
		const result: number[] = []
		for (const b of this.blockchains) {
			result.push(b.chain)
		}
		return result
	}

	getScanUrlForNetwork (network: Network): string | null {
		for (const b of this.blockchains) {
			if (b.network === network) {
				return b.scanUrl
			}
		}
		return null
	}

	getScanAddress (blockchain: string | null, currency: Currency | null, type: TokenType): string | null {
		if (currency === null) {
			return null
		}
		if (type !== 'MAIN') {
			return null
		}
		if (this.blockchains) {
			for (const b of this.blockchains) {
				if (b.name === blockchain) {
					for (const c of b.contracts) {
						if (currency === c.symbol) {
							return `${b.scanUrl}/token/${c.address}`
						}
					}
				}
			}
		}
		return null
	}

	isConvertible (currency: Currency | null, type: TokenType): CurrencyType[] {
		const result: CurrencyType[] = []
		if (currency === null) {
			return result
		}
		if (this.internalTransferable) {
			for (const c of this.internalTransferable) {
				if (c.allowedCurrencies && c.allowedCurrencies?.indexOf(currency) >= 0 && c.fromType === type) {
					result.push({ currency, type: c.toType as TokenType })
				}
			}
		}
		return result
	}

	getBlockchain(currency: Currency | null): string | null {
		if (currency === null) {
			return null
		}
		for (const b of this.blockchains) {
			for (const c of b.contracts) {
				if (currency === c.symbol) {
					return b.name
				}
			}
		}
		return null
	}

	isDepositEnabled(blockchain: string | null, currency: Currency | null, type: TokenType): boolean | null {
		if (currency === null) {
			return null
		}
		if (type !== 'MAIN') {
			return null
		}
		if (this.blockchains) {
			for (const b of this.blockchains) {
				if (b.name === blockchain) {
					for (const c of b.contracts) {
						if (currency === c.symbol) {
							return c.deposit.enabled === true
						}
					}
				}
			}
		}
		return false
	}

	isExplorable(currency: Currency | null, type: TokenType): boolean | null {
		if (currency === null) {
			return null
		}
		if (this.explorable) {
			return this.explorable.indexOf(currency) >= 0
		}
		return false
	}

	isTransferable(currency: Currency | null, type: TokenType): string[] | boolean {
		if (currency === null) {
			return false
		}
		if (this.transferable) {
			for (const t of this.transferable) {
				if (t.currency && t.currency === currency && t.type === type) {
					if (t.whitelist && t.whitelist.length > 0) {
						return t.whitelist
					} else {
						return true
					}
				}
			}
		}
		return false
	}

	isWithdrawEnabled(blockchain: string | null, currency: Currency | null, type: TokenType): boolean | null {
		if (currency === null) {
			return null
		}
		if (type !== 'MAIN') {
			return null
		}
		if (this.blockchains) {
			for (const b of this.blockchains) {
				if (b.name === blockchain) {
					for (const c of b.contracts) {
						if (currency === c.symbol) {
							return c.withdraw.enabled === true
						}
					}
				}
			}
		}
		return false
	}

	otcPairs (currency: Currency | null, type: TokenType): CurrencyType[] {
		let result: CurrencyType[] = []
		if (currency === null) {
			return result
		}
		if (this.marketplaceFTPairs) {
			for (const p of this.marketplaceFTPairs) {
				if (p.currency && p.currency === currency && p.type === type) {
					for (const p2 of p.pairs) {
						result.push({ currency: p2.currency, type: p2.type })
					}
				}
			}
		}
		return result
	}

	hasStaking (currency: Currency | null, type: TokenType): boolean {
		if (currency === null) {
			return false
		}
		if (this.staking) {
			for (const s of this.staking) {
				if (s.currency === currency && s.type === type) {
					return true
				}
			}
		}
		return false
	}

}
