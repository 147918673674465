import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const localeMessages = (() => {
	const locales = require.context('./locales', true, /[A-Za-z0-9-,\s]+\.json$/i)
	const messages: VueI18n.LocaleMessages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
})()

// locale are sorted so they can be iterated in alphabetical order when switching locale
const availableLocales = Object.keys(localeMessages).filter(l => l !== 'common').sort()

export const preferredLocale = (() => {
	const preferences = []
	if (navigator.languages) {
		preferences.push(...navigator.languages)
	}
	if (navigator.language) {
		preferences.push(navigator.language)
	}

	preferences.push(...navigator.languages.map(lang => {
		const delimiter = lang.indexOf('-')
		return delimiter === -1 ? lang : lang.substring(0, delimiter)
	}))

	for (const lang of preferences) {
		if (availableLocales.includes(lang)) {
			return lang
		}
		const delimiter = lang.indexOf('-')
		if (delimiter !== -1 && availableLocales.includes(lang.substring(0, delimiter))) {
			return lang
		}
	}

	return 'en'
})()

export const i18n = new VueI18n({
	messages: localeMessages,
	fallbackLocale: 'common',
	silentTranslationWarn: true,
})

export function langFromLocale (locale: string): string {
	return locale.replace(/-.*/, '')
}

export function countryFromLocale (locale: string): string | null {
	const delimiter = locale.indexOf('-')
	if (delimiter !== -1) {
		return locale.substring(delimiter + 1, locale.length)
	}
	return null
}

export function nextLocale (locale: string): string {
	return availableLocales[(indexOfLocale(locale) + 1) % availableLocales.length]
}

export function restrictLocale (locale: string): string {
	// For now, we just want to deal with fr-FR and en-GB.
	if (langFromLocale(locale) === 'fr') {
		return 'fr-FR'
	} else {
		return 'en-GB'
	}
}

function indexOfLocale (locale: string): number {
	for (let i = 0; i < availableLocales.length; ++i) {
		if (locale === availableLocales[i]) {
			return i
		}
	}
	const delimiter = locale.indexOf('-')
	if (delimiter !== -1) {
		locale = locale.substring(0, delimiter)
		for (let i = 0; i < availableLocales.length; ++i) {
			if (locale === availableLocales[i]) {
				return i
			}
		}
	}
	return -1
}
