import { preferredLocale, restrictLocale } from '@/i18n'
import { Iban, User } from '@/models/User'
import type { JWT } from '@/types'
import { env, mergeDeep } from '@/utils'
import jwtDecode from 'jwt-decode'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { KYInformation } from '@/models/KYInformation'

Vue.use(Vuex)

export interface StoreState {
	chainId: number
	code2FA: string
	code2FATimestamp: number,
	darkMode: boolean
	jwt: string | null
	locale: string
	owner: User | null
	kyInfo: KYInformation
}

const persistence = new VuexPersistence({
	key: env('VUE_APP_NAME'),
	storage: window.localStorage,
})

function getDefaultState (): StoreState {
	return {
		chainId: 0,
		code2FA: '',
		code2FATimestamp: 0,
		darkMode: false,
		jwt: null,
		locale: restrictLocale(preferredLocale),
		owner: new User(null),
		kyInfo: new KYInformation(),
	}
}

export default new Vuex.Store<StoreState>({
	state: getDefaultState(),
	mutations: {
		chainId (state, chainId: number) {
			state.chainId = chainId
		},
		code2FA (state, code2FA: string) {
			state.code2FA = code2FA
		},
		code2FATimestamp (state, code2FATimestamp: number) {
			state.code2FATimestamp = code2FATimestamp
		},
		darkMode (state, darkMode: boolean) {
			state.darkMode = darkMode
		},
		jwt (state, jwt: string | null) {
			state.jwt = jwt
		},
		locale (state, locale: string) {
			state.locale = locale
		},
		owner (state, owner: User) {
			if (owner.ownerId !== state.owner?.ownerId) {
				state.kyInfo = new KYInformation()
			}
			state.owner = new User(owner)
		},
		kyInfo (state, kyInfo: KYInformation) {
			state.kyInfo = mergeDeep(state.kyInfo, kyInfo)
		},
		reset (state) {
			Object.assign(state, getDefaultState())
		},
	},
	getters: {
		parsedJWT (state): JWT | null {
			return state.jwt ? jwtDecode(state.jwt) : null
		},
		isJWTValid (state): boolean {
			const parsedJWT = state.jwt ? jwtDecode(state.jwt) : null
			if (parsedJWT === null) {
				return false
			}
			// @ts-ignore
			return parsedJWT && parsedJWT.exp && parsedJWT.exp < new Date().getTime() / 1000
		},
		isAtLeastKYCLevel1 (state): boolean {
			if (state.owner && state.owner.kyLevel) {
				return state.owner.kyLevel >= 1
			}
			return false
		},
		civicId (state): string | undefined {
			if (state.owner && state.owner.userIds) {
				for (const uid of state.owner.userIds) {
					if (uid.provider === 'civicpower') {
						return uid.id
					}
				}
			}
			return undefined
		},
		enabled2fa (state): boolean {
			if (state.owner && state.owner.enabled2fa) {
				return state.owner.enabled2fa
			}
			return false
		},
		locked2fa (state): boolean {
			if (state.owner && state.owner.locked2fa) {
				return state.owner.locked2fa
			}
			return false
		},
		ibans (state): Iban[] {
			if (state.owner && state.owner.ibans !== undefined) {
				return state.owner.ibans
			}
			return []
		},
		kyc2fa (state, getters): boolean {
			return getters.isAtLeastKYCLevel1 && getters.enabled2fa
		},
	},
	plugins: [persistence.plugin],
})
