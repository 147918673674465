import { alertError, alertWarn, formattedToBigNumber } from '@/utils'
import { BaseProvider, MetaMaskInpageProvider } from '@metamask/providers'
import { Maybe } from '@metamask/providers/dist/utils'
import { BigNumber } from 'bignumber.js'
import { BigNumber as EthersBigNumber, ContractInterface, ethers } from 'ethers'
import type { Currency } from '@/types'
import { Conf } from '@/models/Conf'

declare global {
	interface Window {
		ethereum?: MetaMaskInpageProvider
	}
}

const erc20ABI: ContractInterface = [
	{
		inputs: [],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'owner',
				type: 'address',
			}, {
				indexed: true,
				internalType: 'address',
				name: 'spender',
				type: 'address',
			}, {
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
		],
		name: 'Approval',
		type: 'event',
	}, {
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address',
			}, {
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address',
			}, {
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
		],
		name: 'Transfer',
		type: 'event',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'owner',
				type: 'address',
			}, {
				internalType: 'address',
				name: 'spender',
				type: 'address',
			},
		],
		name: 'allowance',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address',
			}, {
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'approve',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'burn',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address',
			}, {
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'burnFrom',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address payable',
				name: 'seller',
				type: 'address',
			}, {
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'buy',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'payable',
		type: 'function',
	}, {
		inputs: [],
		name: 'decimals',
		outputs: [
			{
				internalType: 'uint8',
				name: '',
				type: 'uint8',
			},
		],
		stateMutability: 'view',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address',
			}, {
				internalType: 'uint256',
				name: 'subtractedValue',
				type: 'uint256',
			},
		],
		name: 'decreaseAllowance',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address',
			}, {
				internalType: 'uint256',
				name: 'addedValue',
				type: 'uint256',
			},
		],
		name: 'increaseAllowance',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	}, {
		inputs: [],
		name: 'name',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string',
			},
		],
		stateMutability: 'view',
		type: 'function',
	}, {
		inputs: [],
		name: 'symbol',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string',
			},
		],
		stateMutability: 'view',
		type: 'function',
	}, {
		inputs: [],
		name: 'totalSupply',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'recipient',
				type: 'address',
			}, {
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'transfer',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	}, {
		inputs: [
			{
				internalType: 'address',
				name: 'sender',
				type: 'address',
			}, {
				internalType: 'address',
				name: 'recipient',
				type: 'address',
			}, {
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'transferFrom',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	},
]

const metamaskProvider = window.ethereum?.isMetaMask ? window.ethereum : null
// const web3Provider = metamaskProvider ? new ethers.providers.Web3Provider(metamaskProvider as BaseProvider) : null

export const isMetamaskInstalled = metamaskProvider !== null

export function getMetamaskProvider (): MetaMaskInpageProvider {
	if (metamaskProvider === null) {
		throw new Error('MetaMask isn\'t installed.')
	}
	return metamaskProvider
}

export function getWeb3Provider (): ethers.providers.Web3Provider {
	const web3Provider = metamaskProvider ? new ethers.providers.Web3Provider(metamaskProvider as BaseProvider) : null
	if (web3Provider === null) {
		throw new Error('MetaMask isn\'t installed.')
	}
	return web3Provider
}

// TODO: delete this
export async function changeChain (conf: Conf, chainId: number): Promise<boolean> {
	const blockchain = conf.getChain(chainId)
	if (blockchain === null) {
		alertError('metamask.unknownChain')
	}
	const chainIdHex = '0x' + chainId.toString(16)
	try {
		await getMetamaskProvider().request({ method: 'wallet_switchEthereumChain', params: [{ chainId: chainIdHex }] })
		return true
	} catch (switchError: any) {
		if (switchError.code === 4902) {
			try {
				await getMetamaskProvider().request({
					method: 'wallet_addEthereumChain',
					params: [{
						chainId: chainIdHex,
						blockExplorerUrls: [blockchain?.scanUrl],
						chainName: blockchain?.name,
						nativeCurrency: {
							name: blockchain?.symbol,
							symbol: blockchain?.symbol,
							decimals: blockchain?.precision,
						},
						rpcUrls: blockchain?.rpcUrls, // [ 'https://bsc-dataseed.binance.org/' ]
					}],
				})
			} catch (addError: any) {
				if (addError.code === 4001) {
					// EIP-1193 userRejectedRequest error
					// If this happens, the user rejected the sign request.
					alertWarn('metamask.requestCanceled')
				} else {
					alertError('metamask.requestFailed', addError.data.message)
				}
			}
		} else {
			if (switchError.code === 4001) {
				// EIP-1193 userRejectedRequest error
				// If this happens, the user rejected the sign request.
				alertWarn('metamask.requestCanceled')
			} else {
				alertError('metamask.requestFailed', switchError.data.message)
			}
		}
		return false
	}
}

export async function connect (): Promise<string | null> {
	try {
		const addresses = await getMetamaskProvider().request({ method: 'eth_requestAccounts' }) as string[]
		return addresses[0] // always contains one element (https://docs.metamask.io/guide/rpc-api.html#eth-requestaccounts)
	} catch (err: any) {
		if (err.code === 4001) {
			// EIP-1193 userRejectedRequest error
			// If this happens, the user rejected the sign request.
			alertWarn('metamask.requestCanceled')
		} else {
			alertError('metamask.requestFailed', err.data.message)
		}
		return null
	}
}

export async function getSelectedAddress (): Promise<string | undefined> {
	try {
		const addresses = await getMetamaskProvider().request({ method: 'eth_accounts' }) as string[]
		return addresses[0]
	} catch (err: any) {
		alertError('metamask.requestFailed', err.data.message)
		return undefined
	}
}

export async function getChainId (): Promise<number | null> {
	try {
		const hexChainId = await getMetamaskProvider().request({ method: 'eth_chainId' }) as string
		return parseInt(hexChainId, 16)
	} catch (err: any) {
		alertError('metamask.requestFailed', err.data.message)
		return null
	}
}

// TODO: delete this
/* export async function getBalance (conf: Conf, currency: Currency): Promise<BigNumber | null> {
	const selectedAddress = await getSelectedAddress()
	if (!selectedAddress) {
		return null
	}

	const chainId = await getChainId()
	if (!chainId) {
		return null
	}

	const chain = conf.getChain(chainId)
	if (!chain) {
		return null
	}
	const contract = chain.getContract(currency)
	if (!contract || !contract.address) {
		return null
	}
	const web3Provider = getWeb3Provider()
	const currencyContract = new ethers.Contract(contract.address, erc20ABI, web3Provider)
	const decimals = await currencyContract.decimals()
	const signer = await web3Provider.getSigner()
	const currencyWithSigner = await currencyContract.connect(signer)
	try {
		const balance = await currencyWithSigner.balanceOf(selectedAddress) as EthersBigNumber
		return formattedToBigNumber(balance.toString(), decimals)
	} catch (err: any) {
		if (err.code === 4001) {
			// EIP-1193 userRejectedRequest error
			// If this happens, the user rejected the connection request.
			alertWarn('metamask.requestCanceled')
		} else if (err.data && err.data.message) {
			alertError('metamask.transactionFailed', err.data.message)
		} else {
			alertError('metamask.transactionFailedUnknownReason')
		}
		return null
	}
}*/

export async function signMessage (message: string): Promise<string | null> {
	const selectedAddress = await getSelectedAddress()
	if (!selectedAddress) {
		return null
	}
	try {
		const signedMessage = await getMetamaskProvider().request({
			method: 'personal_sign',
			params: [selectedAddress, message],
		}) as Maybe<string>
		if (!signedMessage) {
			alertError('metamask.signatureFailedUnknownReason')
			return null
		}
		return signedMessage
	} catch (err:any) {
		if (err.code === 4001) {
			// EIP-1193 userRejectedRequest error
			// If this happens, the user rejected the sign request.
			alertWarn('metamask.requestCanceled')
		} else {
			alertError('metamask.signatureFailed', err.data.message)
		}
		return null
	}
}

// TODO: delete this
export async function transfer (conf: Conf, amount: BigNumber, currency: Currency, to: string): Promise<string | null> {
	const chainId = await getChainId()
	if (!chainId) {
		return null
	}
	const chain = conf.getChain(chainId)
	if (!chain) {
		return null
	}
	const contract = chain.getContract(currency)
	if (!contract || !contract.address) {
		return null
	}
	const web3Provider = getWeb3Provider()
	const currencyContract = new ethers.Contract(contract.address, erc20ABI, web3Provider)
	const signer = await web3Provider.getSigner()
	const currencyAmount = await ethers.utils.parseUnits(amount.toString(), await currencyContract.decimals())
	const currencyWithSigner = await currencyContract.connect(signer)
	try {
		const result = await currencyWithSigner.transfer(to, currencyAmount)
		return result.hash ?? null
	} catch (err:any) {
		if (err.code === 4001) {
			// EIP-1193 userRejectedRequest error
			// If this happens, the user rejected the connection request.
			alertWarn('metamask.requestCanceled')
		} else if (err.data && err.data.message) {
			alertError('metamask.transactionFailed', err.data.message)
		} else {
			alertError('metamask.transactionFailedUnknownReason')
		}
		return null
	}
}
