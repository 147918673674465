<template>
	<div
			v-bind:class="{
      'react-code-input-container': true,
      [className]: !!className
    }"
	>
		<p class="title" v-if="title">{{ title }}</p>
		<div class="react-code-input">
			<v-otp-input v-model="otp" type="number" length="6" ref="otpInput" :disabled="loading" @finish="onFinish"></v-otp-input>
      <v-overlay absolute :value="loading" style="height: 65px">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
		</div>
	</div>
</template>

<script>

export default {
	name: 'CodeInput',
	props: {
		type: {
			type: String,
			default: 'number',
		},
		className: String,
		title: String,
		complete: Function,
		focus: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			otp: null,
			snackbar: null,
			loading: false,
		}
	},

	watch: {
		focus: {
			handler (val) {
				if (val === true) {
					this.autoFocus()
				}
			},
			immediate: true,
		},
	},

	methods: {
		autoFocus () {
			this.$nextTick(() => {
				if (this.$refs.otpInput) {
					this.$refs.otpInput.focus()
				}
			})
		},

		onFinish (values = this.values) {
			this.loading = true
			setTimeout(() => {
				this.$emit('complete', values)
				this.loading = false
			}, 2000)
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.react-code-input-container {
	position: relative;
}
.react-code-input > input {
	border: solid 1px;
	border-right: none;
	font-size: 20px;
	color: #525461;
	text-align: center;
	box-sizing: border-box;
	border-radius: 0;
	-webkit-appearance: initial;
}
.react-code-input > input:last-child {
	border-right: solid 1px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}
.react-code-input > input:first-child {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
.react-code-input > input:focus {
	outline: none;
	border: 1px solid #006fff;
	caret-color: #006fff;
}
.react-code-input > input:focus + input {
	border-left: none;
}
.loading {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}
.blur {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	opacity: 0.5;
	filter: blur(0.5px);
	transition: opacity 0.3s;
}
.title {
	margin: 0;
	height: 20px;
	padding-bottom: 10px;
}
.spin {
	display: inline-block;
	animation: loadingCircle 1s infinite linear;
}
@keyframes loadingCircle {
	100% {
		transform: rotate(360deg);
	}
}
</style>
