


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationRule } from '@/types'
import { BigNumber } from 'bignumber.js'
import { convertValueToBigNumber, formatFixedDecimalsNoUselessZero } from '@/utils'

// TODO: not good, checkBalance, balance and min are linked with each other ...
@Component
export default class CurrencyInput extends Vue {
	@Prop() lang?: string
	@Prop({ default: -1 }) precision!: number
	@Prop({ default: new BigNumber('NaN') }) value!: BigNumber
	@Prop({ default: false }) checkBalance!: boolean // TODO: change that it must be combined with min
	@Prop({ default: false }) dense!: boolean
	@Prop() currency?: string
	@Prop() currencyType?: string
	@Prop() autocomplete?: string
	@Prop() balance?: BigNumber
	@Prop() color?: string
	@Prop({ default: false }) disabled!: boolean | string
	@Prop() label?: string
	@Prop() loading?: boolean
	@Prop() max?: BigNumber
	@Prop() maxButton?: boolean
	@Prop() min?: BigNumber // TODO: change that it must be combined with checkBalance
	@Prop() outlined?: boolean
	@Prop() readonly?: boolean
	@Prop() required?: boolean
	@Prop({ default: () => [] }) rules?: ((v: string) => true | string)[]

	private sValue = ''

	private get displayedValue (): string {
		return this.sValue
	}

	private set displayedValue (value: string) {
		if (this.displayedValue === value) {
			return
		}
		let newValue
		let snewValue = ''
		if (value === '') {
			newValue = new BigNumber(0)
			snewValue = '0'
		} else {
			const v = this.trimLeftZeros(value)
			if (v === null) {
				newValue = new BigNumber(NaN)
			} else {
				newValue = new BigNumber(v)
				snewValue = v
			}
		}
		if (!newValue.isNaN() && newValue.gte(0) && (!this.max || newValue.integerValue(BigNumber.ROUND_UP).toFixed().length < this.max.integerValue(BigNumber.ROUND_UP).toFixed().length + 1)) {
			this.sValue = snewValue
			if (!this.value.eq(newValue)) {
				// to avoid circular call when parent component set the value after component creation
				this.value = newValue
			}
			this.$emit('input', this.value)
		}
		// this is used to update the text field with the real value stored in the component
		this.$nextTick(() => {
			const inputElement = this.$refs.currencyInput
			if (inputElement) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				inputElement.$data.lazyValue = this.sValue
			}
		})
	}

	private get mainRule (): ValidationRule {
		const rules = this.precision === -1
			? []
			: [
				(value: string) => !(this.precision === 0 && value.includes('.')) || this.tr('currencyInput.integerOnly'),
				(value: string) => {
					const [, floats = ''] = value.split('.')
					return floats.length <= this.precision || this.tr('currencyInput.invalidTitle', { precision: this.precision })
				},
				(v?: string) => !!v || this.tr('rule.requiredField'),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.tr('rule.requiredField'),
			]
		// TODO: make this more generic, specific to checkout for now
		if (this.balance && this.checkBalance && this.min) {
			rules.push((v?: string) => {
				return (!!v && this.balance && this.checkBalance && this.min && new BigNumber(this.min).isLessThanOrEqualTo(this.balance)) || this.tr('checkout.rule.insufficientFundsCrypto', {
					balance: this.balance,
					currency: this.currency,
					min: this.min,
				})
			})
		}
		if (this.min !== undefined) {
			rules.push((v?: string) => (!!v && convertValueToBigNumber(v).gte(this.min ?? new BigNumber(0))) || this.tr('ftmpOffer.rule.min', { min: this.min }),)
		}
		if (this.max !== undefined) {
			rules.push((v?: string) => {
				return (!!v && convertValueToBigNumber(v).isLessThanOrEqualTo(this.max ?? new BigNumber(0))) || this.tr('ftmpOffer.rule.max', {
					max: formatFixedDecimalsNoUselessZero(this.$i18n, this.max ?? new BigNumber(0), this.precision),
				})
			})
		}
		return rules
	}

	private get realMax (): BigNumber {
		return this.max && this.precision >= 0 ? new BigNumber(this.max.toFixed(this.precision)) : new BigNumber(0)
	}

	private get suffix (): string {
		if (this.currency && this.currencyType) {
			return this.currency + ' ' + this.currencyType
		} else if (this.currency && !this.maxButton) {
			return this.currency
		}
		return ''
	}

	@Watch('value')
	refresh (): void {
		this.displayedValue = this.value.toString()
	}

	onClickMax (): void {
		this.displayedValue = this.realMax.toString()
	}

	// TODO: What is it for ?
	onKeyPress (e: KeyboardEvent): void {
		this.$emit('keypress', e)
	}

	private tr (k: string, v?: any): string {
		if (this.lang) {
			return this.$t(k, this.lang, v) ? this.$t(k, this.lang, v).toString() : ''
		} else {
			return this.$t(k, v).toString()
		}
	}

	// not very academic !
	private trimLeftZeros (value: string): string | null {
		if (this.precision === 0 && value.split('.').length > 1) {
			return null
		}
		if (value.split('.').length > 2) {
			return null
		}
		const [left, right = ''] = value.split('.')
		if (value.indexOf('.') > 0) {
			return new BigNumber(left).toString() + '.' + right
		} else {
			return new BigNumber(left).toString()
		}
	}

	private async mounted (): Promise<void> {
		this.displayedValue = this.value.toString()
	}
}
