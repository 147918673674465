







import { Component, Vue } from 'vue-property-decorator'
import AppLayout from '@/layouts/AppLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import PublicLayout from '@/layouts/PublicLayout.vue'

@Component({
	components: { AppLayout, EmptyLayout, PublicLayout },
})
export default class App extends Vue {

	get domain (): string {
		return location.host
	}

	private get	layout (): string {
		switch (this.$route.meta?.layout) {
			case 'empty':
				return 'EmptyLayout'
			case 'public':
				return 'PublicLayout'
			default:
				return 'AppLayout'
		}
	}

}
